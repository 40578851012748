import { useEffect, useRef, useState } from "react";
import "./edit-profession-post.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormFieldError from "../../../components/form-field-error/form-field-error";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import ValidationError from "../../../common/validation-error";
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import LocationApiService from "../../../data/api/services/location/location-api-service";
import { Carousel, Col, Row } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup } from "react-bootstrap";
const EditProfessionPost = (props: any) => {

    const professionalPageApiservice = new ProfessionalPageApiService();
    const locationApiService = new LocationApiService
    const propertyApiService = new PropertyApiService();
    const properyCoverImageInput: any = useRef(null);
    const maxLength = 100;
    const minLength = 0;
    let navigate: any = useNavigate();
    const [locationSearchLoading, setLocationSearchLoading] = useState(false);
    const [locationData, setLocationData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState<any>([]);
    const [toDeleteImageId, setToDeleteImageId] = useState(0);
    const [callToActionData, setCallToActionData] = useState([]);
    const [callToActionIdStr, setCallToActionIdStr] = useState("");
    const [showCallToActionOption, setShowCallToActionOption] = useState(true);
    const [description, setDescription] = useState('');
    const [callToActionUrl, setCallToActionUrl] = useState('');
    const [professionalPagePosts, setProfessionalPagePosts] = useState<any>([]);
    const [postImage, setPostImage] = useState<any>([]);
    const [index, setIndex] = useState(0);
    const [locationValidationErrorMsg, setLocationValidationNameErrorMsg] = useState(null);
    const [callToActionValidationErrorMsg, setCallToActionValidationErrorMsg] = useState(null);
    const [professionalPagePostFormData, setProfessionalPagePostFormData] = useState<any>(new FormData());
    const [professionalPostFormData, setProfessionalPostFormData] = useState<any>(new FormData());
    const [tagInput, setTagInput] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const [descriptionValidationErrorMsg, setDescriptionValidationErrorMsg] = useState(null);
    const [callToActionUrlValidationErrorMsg, setCallToActionUrlValidationErrorMsg] = useState(null);
    const [tags, setTags] = useState<string[]>([]);
    const [postTagsTemplateData, setPostTagsTemplateData] = useState([]);

    const [hoveredTag, setHoveredTag] = useState<string | null>(null);
    const [postImageValidationErrorMsg, setPostImageValidationErrorMsg] = useState('');
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [carouselKey, setCarouselKey] = useState(0);
    const [confirmationDialogParams, setConfirmationDialogParams] = useState({
        title: '',
        message: '',
        onConfirm: () => { },
    });

    const handleCallToActionChange = (btId: any) => {
        setCallToActionIdStr(btId);
        setShowCallToActionOption(false);
    };

    const handleDescriptionOnChange = (ds: any) => {
        setDescription(ds);
    };

    const handleCallToActionUrlOnChange = (cu: any) => {
        setCallToActionUrl(cu);
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTagInput(event.target.value);
    };
    const addTag = () => {
        if (tagInput.trim() !== '') {
            if (!tags.includes(tagInput.trim())) {
                setTags([...tags, tagInput.trim()]);
            }
            setTagInput('');
        }
        console.log("added tags", tags)
    };

    const onUploadPostListener = (e: any) => {
        if (e.currentTarget.files) {
            let newImages: any = [];
            for (let i = 0; i < e.currentTarget.files.length; i++) {
                let newImage: any = {};
                newImage.file = e.currentTarget.files[i];
                newImage.url = URL.createObjectURL(newImage.file);
                newImages.push(newImage);
            }
            setPostImage((prevImages) => [...prevImages, ...newImages]);
            setPostImageValidationErrorMsg('');
        } else {
            console.error("No files found in the event");
        }
    };

    const clearCoverImage = (index: number) => {
        const updatedImages = [...postImage];
        updatedImages.splice(index, 1);
        setPostImage(updatedImages);
        setCarouselKey((prevKey) => prevKey + 1);
    }

    const deleteProfessionalPagePost = async (id: any, imageId: any) => {
        try {
            if (id) {
                await professionalPageApiservice.deleteProfessionalpagePostImage(id, imageId);

                return;
            }
        } catch (error) {
            console.log("ERROR occured deleteProperty() error=== " + JSON.stringify(error));
        }
    };

    const onProfessionalPageUploadPostListener = (event: any) => {
        const files = event.target.files;
        if (files.length > 0) {
            let mediaFiles: any = [];
            for (let i = 0; i < files.length; i++) {
                let mediaFile: any = {};
                mediaFile.file = files[i];
                if (mediaFile.file.type.startsWith('image/')) {
                    mediaFile.mimeType = 'IMAGE';
                }
                else if (mediaFile.file.type.startsWith('video/')) {
                    mediaFile.mimeType = 'VIDEO';
                }
                mediaFile.imagePath = URL.createObjectURL(mediaFile.file);
                mediaFiles.push(mediaFile);
            }
            setPostImage(prevImages => [...prevImages, ...mediaFiles]);
        } else {
            setPostImage([]);
        }
    }

    const validateUploadPostFormData = (): void => {
        let errors: any[] = [];

        if (selectedLocation.length === 0) {
            let error: any = {};
            error.validationCode = "taggedLocationIdStr";
            error.message = 'Location required';
            errors.push(error);
        }

        if (postImage <= 0) {
            let error: any = {};
            error.validationCode = "mediaUrl";
            error.message = 'Image required';
            errors.push(error);
        }

        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }
    const getProfessionalPagePostTagTemplateData = async () => {
        let data = await professionalPageApiservice.getProfessionalPagePostTagTemplateData();
        console.log(data);
        setPostTagsTemplateData(data);
    };
    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;

            if (errorCode === 'mediaUrl') {
                setPostImageValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'taggedLocationIdStr') {
                setLocationValidationNameErrorMsg(errorMsg);
            }
        }
    }

    const handleRemoveImageConfirmation = (index: number) => {
        const imageData = professionalPagePosts.professionalPagePostImageGalleryData[index];
        if (imageData && imageData.id) {
            setConfirmationDialogParams({
                title: 'Remove Image',
                message: 'Are you sure to remove this image?',
                onConfirm: () => clearCoverImage(index),
            });
            setToDeleteImageId(imageData.id);
            setShowConfirmationDialog(true);
        } else {
            clearCoverImage(index);
        }
    };

    const handleConfirmDialog = () => {
        confirmationDialogParams.onConfirm();
        setShowConfirmationDialog(false);
        deleteProfessionalPagePost(professionalPagePosts.id, toDeleteImageId)
    };

    const handleCancelDialog = () => {
        setShowConfirmationDialog(false);
    };

    const validateAndUploadPagePostFormData = () => {
        try {
            validateUploadPostFormData();
            if (professionalPostFormData.has("mediaUrl")) {
                professionalPostFormData.delete('mediaUrl');
            } for (let i = 0; i < postImage.length; i++) {
                professionalPostFormData.append('mediaUrl', postImage[i].file);
            }

            if (professionalPostFormData.has('callToActionIdStr')) {
                professionalPostFormData.delete('callToActionIdStr');
            }
            professionalPostFormData.append('callToActionIdStr', callToActionIdStr);

            if (professionalPostFormData.has("professionalPostIdStr")) {
                professionalPostFormData.delete('professionalPostIdStr');
            }
            professionalPostFormData.append('professionalPostIdStr', professionalPagePosts.professionalPageId);

            if (professionalPostFormData.has('taggedLocationIdStr')) {
                professionalPostFormData.delete('taggedLocationIdStr');
            }
            if (selectedLocation.length > 0) {
                professionalPostFormData.append('taggedLocationIdStr', selectedLocation[0].id);
            }

            if (professionalPostFormData.has('caption')) {
                professionalPostFormData.delete('caption');
            }
            professionalPostFormData.append('caption', description);

            if (professionalPostFormData.has("tags")) {
                professionalPostFormData.delete('tags');
            }
            professionalPostFormData.append('tags', concatenatedTags);
            if (professionalPostFormData.has('callToActionUrl')) {
                professionalPostFormData.delete('callToActionUrl');
            }
            professionalPostFormData.append('callToActionUrl', callToActionUrl);


        } catch (error: any) {
            throw error;
        }
    }

    const updateProfessionalPagePosts = async () => {
        try {
            validateAndUploadPagePostFormData();
            setLoading(true);
            console.log("Form Data Details:");
            for (const entry of professionalPostFormData.entries()) {
                console.log(entry);
            }
            let data = await professionalPageApiservice.updateProfessionalPagePost(props.postId, professionalPostFormData);
            console.log(data)
            toast.success('Updated Post Successfully', { containerId: 'TR' });
            window.location.reload();
        } catch (error: any) {
            showValidationErrorInlineUI(error);
        }
    }

    const searchLocation = async (q: any) => {
        setLocationSearchLoading(true);
        let data = await propertyApiService.searchLocation(q);
        console.log(q);
        setLocationSearchLoading(false);
        formatLocationData(data);
    };

    const formatLocationData = (data: any) => {
        formatLocationDataResponse(data);
        setLocationData(data);
    };
    
    const formatLocationDataResponse = (data: any) => {
        for (let i = 0; i < data.length; i++) {
            data[i].nameLabel = data[i].name + ' | ' + data[i].pinCode + ' | ' + data[i].postOfficeName+' | ' + data[i].talukaName+  ' | ' + data[i].districtName+ ' | ' + data[i].abbreviation;
        }
    };

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const onLocationSelected = (e: any) => {
        if (e.length > 0) {
            console.log(e[0]);
            setSelectedLocation(e);
        }
        setLocationValidationNameErrorMsg('');
    };

    const clearSelectedLocation = () => {
        setSelectedLocation([]);
    };

    const getTemplateData = async () => {
        let data = await professionalPageApiservice.getProfessionalPageTemplateData();
        console.log(data);
        setCallToActionData(data.callToActionData);
    };

    const getProfessionalPagePostDataById = async () => {
        try {
            let res = await professionalPageApiservice.getProfessionalPagePostData(props.postId);
            setProfessionalPagePosts(res);
            highlightProfessionalPagePostFormData(res);
        } catch (error) {
            console.log(error);
        }
    };
    const concatenatedTags = tags.join(',');

    const removeTag = (tagToRemove: string) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
    };
    const highlightProfessionalPagePostFormData = async (post) => {
        setDescription(post.caption);
        const professionalPagePostTagData = post.professionalPagePostTagData;
        const tagNames = professionalPagePostTagData.map(tagData => tagData.tagName);
        console.log("tags when api call", tagNames)
        setTags([...tagNames])
        setCallToActionUrl(post.callToActionUrl);
        setCallToActionIdStr(post.callToAction);

        hightlightProfessionalPageProfileImage(post);
        const locationIdObj = (post.taggedLocationId);
        const selectedLocationData = await locationApiService.getLocationById(locationIdObj);
        const formattedLocation = `${selectedLocationData.name} | ${selectedLocationData.pinCode}|${selectedLocationData.postOfficeName} | ${selectedLocationData.talukaName}| ${selectedLocationData.districtName}| ${selectedLocationData.stateName}`;
        setSelectedLocation([{ id: locationIdObj, nameLabel: formattedLocation }]);
    }
    const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ',' || event.key === 'Enter') {
            event.preventDefault();
            addTag();
        }
    };
    const renderMenuItemChildren = (option, props, index) => {
    
        return (
        <div key={index}>
        <span style={{ color: 'black' }}>{option.name}</span>
        {' | '}
        <span style={{ color: 'blue' }}>{option.pinCode}</span>
        {' | '}
        <span style={{ color: 'red' }}>{option.postOfficeName}</span>
        {' | '}
        <span style={{ color: 'green' }}>{option.talukaName}</span>
        {' | '}
        <span style={{ color: 'orange' }}>{option.districtName}</span>
        {' | '}
        <span style={{ color: '#fa0acc' }}>{option.abbreviation}</span>
        
      </div>
    );
      };
    const hightlightProfessionalPageProfileImage = async (post) => {
        try {
            // const postImages = post.professionalPagePostImageGalleryData.map(item => ({
            //     imagePath: item.imagePath,
            // }));
            // setPostImage(prevImages => [...prevImages, ...postImages]);
            setPostImage(post.professionalPagePostImageGalleryData);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getProfessionalPagePostDataById();
        getTemplateData();
        getProfessionalPagePostTagTemplateData();

    }, []);

    return <>
        <div style={{ position: 'relative' }}>
        {loading && (
                <div className="d-flex justify-content-center align-items-center"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 10
                    }}>
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            )}
        <div className="p-3">
            <div className="d-flex justify-content-between mb-1">
                <div>
                    <button className="btn" onClick={props.handleClose} ><ArrowBackIcon /></button>
                    <span className="ms-3 fs-5 fw-bold">Edit Post</span>
                </div>
                <div>
                    <button className="btn" onClick={props.handleClose} ><CloseIcon /></button>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-sm-6 mb-3">
                    <label className="mb-2">Call To Action</label>
                    <select className={callToActionValidationErrorMsg ? ' call-to-action error-field input-edit form-control' : 'call-to-action input-edit form-control'}
                        value={callToActionIdStr}
                        onChange={(e) => handleCallToActionChange(e.target.value)}>
                        {showCallToActionOption && <option>Select </option>}
                        {callToActionData && callToActionData.map((ctaData: any, index: any) => (<option className="text-edit" key={ctaData.id} value={ctaData.id}>{ctaData.label}</option>))}
                    </select>
                    <FormFieldError errorMessage={callToActionValidationErrorMsg}></FormFieldError>
                </div>

                <div className="col-sm-6 mb-3">
                    <label className="mb-2">Call To Action Url</label>
                    <input
                        type="text"
                        className={`call-to-action input-value form-control p-3 ${callToActionUrlValidationErrorMsg ? 'error-field input-edit' : ''}`}
                        placeholder="Call To Action Url"
                        value={callToActionUrl !== "null" ? callToActionUrl : ''}
                        minLength={minLength}
                        maxLength={maxLength}
                        onChange={e => { handleCallToActionUrlOnChange(e.target.value) }}
                    />
                    <FormFieldError errorMessage={callToActionUrlValidationErrorMsg}></FormFieldError>
                </div>
            </div>

            <div className="row mt-3">
                <Col className="mb-2">
                    <textarea
                        className={`input-value form-control p-3 ${descriptionValidationErrorMsg ? 'error-field input-edit' : ''}`}
                        placeholder="Add description"
                        value={description !== null && description !== "null" ? description : ''}
                        minLength={minLength}
                        maxLength={maxLength}
                        onChange={e => { handleDescriptionOnChange(e.target.value) }}
                        rows={3} cols={28}>
                    </textarea>
                    <FormFieldError errorMessage={descriptionValidationErrorMsg}></FormFieldError>
                    <div className='under-text m-0 mt-2'>
                        <div className="d-flex justify-content-between">
                            <span>Remaining:</span>
                            <span>{minLength + (description !== null && description !== "null" ? description : '').length}/1000</span>
                        </div>
                    </div>
                </Col>
            </div>
            <div className="row mt-3">
                <Col className="mb-2">
                    <label className="mb-2">Tag</label>
                    <div className="tag-container">
                        {tags.map(tag => (
                            <div key={tag} className="tag" style={hoveredTag === tag ? { border: '#ff5c5c solid 3px' } : {}}>
                                <span className="tag-text">{tag}</span>
                                <button className="tag-remove-btn px-1"
                                    onMouseEnter={() => setHoveredTag(tag)}
                                    onMouseLeave={() => setHoveredTag(null)}
                                    onClick={() => removeTag(tag)}>X</button>
                            </div>
                        ))}
                    </div>
                    <input
                            type="text"
                            className="form-control p-3"
                            value={tagInput}
                            onChange={handleInputChange}
                            onKeyPress={handleInputKeyPress}
                            list="ctaOptions"
                            placeholder="Add tags and press Enter"
                        />
                         <datalist id="ctaOptions" style={{ maxHeight: '20px' }}>
                            {postTagsTemplateData && postTagsTemplateData.map((tagData) => (
                                <option key={tagData.id} value={tagData.tagName} />
                            ))}
                        </datalist>
                    <button className="btn btn-primary mt-2 rounded-pill" onClick={addTag}>Add</button>
                </Col>
            </div>
            <div className="row mt-3">
                <div className="col-sm-6 mb-5">
                    <label className="mb-2">Tag Location<sup className="required">*</sup></label>
                    <div>
                    <Col className="justify-content-end align-items-center d-flex async-container">
                                                <Col xs="auto" className="close-icon">
                                    {selectedLocation.length > 0 && (

                                        <FontAwesomeIcon icon={faTimes} onClick={clearSelectedLocation} />
                                    )}
                                </Col>
                                <Col>
                        <AsyncTypeahead
                            className={` test ${locationValidationErrorMsg ? 'error-field input-edit' : ''}`}
                            labelKey="nameLabel"
                            onSearch={(e) => searchLocation(e)}
                            options={locationData}
                            onChange={onLocationSelected}
                            useCache={false}
                            placeholder="Search Location"
                            defaultSelected={selectedLocation}
                            isLoading={locationSearchLoading}
                            selected={selectedLocation} 
                            renderMenuItemChildren={renderMenuItemChildren}
                            />
                            </Col>
                            </Col>
                     <FormFieldError errorMessage={locationValidationErrorMsg}></FormFieldError>
                    </div>
                    <div className='below-text mt-3 m-0'>
                        {selectedLocation.length > 0 && (
                            <button onClick={clearSelectedLocation} className="btn btn-danger mb-2 rounded-pill" type="button">
                                Clear
                            </button>
                        )}
                    </div>
                </div>

                <div className="col-sm-6 text-center mb-3">
                    <div>
                        <input
                            type="file"
                            className="visually-hidden"
                            ref={properyCoverImageInput}
                            onChange={onProfessionalPageUploadPostListener}
                            multiple
                            accept="image/,video/"
                            id="fileInput"
                        />
                        <label
                            htmlFor="fileInput"
                            className="img-box text-primary custom-file-input"
                            onClick={onUploadPostListener}
                        >
                            <AddAPhotoIcon /> <span className="ms-2"> Edit Photos</span>
                        </label>
                        <input
                            type="button"
                            className="border rounded border-dark btn-height-size"
                            onClick={onUploadPostListener}
                        />
                        <FormFieldError errorMessage={postImageValidationErrorMsg}></FormFieldError>
                    </div>

                    <div>
                        <Carousel
                            key={carouselKey}
                            activeIndex={index}
                            onSelect={handleSelect}
                            interval={null}
                            indicators={postImage.length > 1}
                            controls={postImage.length > 1}
                            prevIcon={<FaChevronLeft style={{ color: '#0b7ec1', fontSize: '2em', background: 'aliceblue' }} />}
                            nextIcon={<FaChevronRight style={{ color: '#0b7ec1', fontSize: '2em', background: 'aliceblue' }} />} >
                            {postImage.length > 0 &&
                                postImage.map((postImg: any, index: number) => (
                                    <Carousel.Item>
                                        <Row key={'post_image_' + index} className="" >
                                            <div className="col-sm-12 d-flex align-items-center justify-content-center">
                                                {/* <img src={postImg.imagePath} width={"100%"} height={200} alt={`Post Image ${index}`} /> */}
                                                {postImg.mimeType === 'IMAGE' ? (
                                                    <img src={postImg.imagePath} width={'100%'} height={'200px'} />
                                                ) : (
                                                    <>
                                                        <video width={'100%'} height={'200px'}>
                                                            <source src={postImg.imagePath} />
                                                        </video>
                                                        <div className="d-flex align-items-center justify-content-center video-play-icon">
                                                            <PlayCircleOutlineIcon style={{ fontSize: 'xxx-large' }} />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="col-sm-12 d-flex justify-content-start">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm rounded-pill mt-2"
                                                    aria-label="Close"
                                                    onClick={() => handleRemoveImageConfirmation(index)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </Row>
                                    </Carousel.Item>
                                ))}
                        </Carousel>

                    </div>
                </div>
                {showConfirmationDialog && (
                    <div className="confirmation-dialog">
                        <div className="confirmation-dialog-content">
                            <h2>{confirmationDialogParams.title}</h2>
                            <p>{confirmationDialogParams.message}</p>
                            <button onClick={handleConfirmDialog} className="confirmation-dialog-button">
                                Yes
                            </button>

                            <button onClick={handleCancelDialog} className="confirmation-dialog-button cancel">
                                No
                            </button>

                        </div>
                    </div>
                )}

                <div className="text-end p-3">
                    <button className="btn btn-success" onClick={updateProfessionalPagePosts} type="button">Upload Post</button>
                </div>
            </div>
        </div>
           
         </div>
    </>
}
export default EditProfessionPost;