import "./profession-post-tag.css";
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Sale from "../../../assets/for sale.jpg";
import Rent from "../../../assets/for rent.jpg";
import Land from "../../../assets/land.jpg";
import Model from "../../../assets/model house.jpg";
import AsyncTypeahead from "react-bootstrap-typeahead/types/components/AsyncTypeahead";
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import React from "react";
import TuneIcon from '@mui/icons-material/Tune';
import ProfilePic from "../../../assets/profile-pic.jpeg";
import StorageService from "../../../data/storage/storage-service";
import { useDispatch } from "react-redux";
import { Col, Figure, InputGroup, Modal, Nav, Row } from "react-bootstrap";
import map from "../../../assets/map.png";
import { faArrowRight, faBars, faCrosshairs, faMagnifyingGlass, faMap, faTag } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import FormFieldError from "../../../components/form-field-error/form-field-error";
import HomeProfessionalFollow from "../../home/components/home-professional-follow";
import HomeProfessionalFollowing from "../../home/components/home-professional-following";
import HomeProfessionalList from "../../home/components/home-professional-list";
import ProfessionLocationSearch from "../../home/components/profession-location-search";
import ProfessionTypeSearch from "../../home/components/profession-type-search";
import { PropertySearchAction } from "../../home/state/search-action";
import { Footer, Header } from "../../../layouts";
import elevation from "../../../assets/front.elevation.jpeg";
import avatar from "../../../assets/avatar.png";
import person from "../../../assets/profile-pic.jpeg"
import crown from "../../../assets/crown.png"

import DefaultImage from "../../../assets/tag.png"
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import { toast } from "react-toastify";
import { PagePostTagFollowPayload } from "../../../data/api/services/professional-page/professional-page-payload";
import TagSearch from "../tag-search/tag-search";



const ProfessionPostTag = (props: any) => {
    const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        console.log(event.target.value);
    };
    let navigate: any = useNavigate();
    const handleIconClick = () => {

        setEditShow(true);
    };

    const [tagSearchLoading, setTagSearchLoading] = useState(false);
    const [data, setData] = useState<any>([]);
    const [isFollowing, setIsFollowing] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [newest, setNewest] = useState(' ');
    const [premium , setPremium] = useState(' ');
    const [free , setFree] = useState(' ');
     const [oldest, setOldest] = useState(' ');
     const [popularity, setPopularity] = useState(' ');
     const [followers, setFollowers] = useState(' ');

     const [viewers, setViewers] = useState(' ');
     const [users, setUsers] = useState(' ');




    const [userPageData, setUserPageData] = useState<any>([]);
    const activePageItem = userPageData.find(item => item.activePage === true);
    const userPageId = activePageItem?.id;
    const userId = StorageService.getUserId();
    const [editShow, setEditShow] = useState(false);

    const [professionalPagePostTags, setProfessionalPagePostTags] = useState<any>([]);
    
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10000000);

    const propertyApiService = new PropertyApiService();
    const professionalPageApiService = new ProfessionalPageApiService();
    const [professionalPagePosts, setProfessionalPagePosts] = useState<any>([]);


    const [tagData, setTagData] = useState([]);

    const imageArray = [1, 2, 3, 4];

    const [selectedTag, setSelectedTag] = useState([]);




    const dispatch = useDispatch();


    const [selectedTagId, setSelectedTagId] = useState('');


    const [selectedTagName, setSelectedTagName] = useState('');

    const [selectedParticularTagName, setSelectedParticularTagName] = useState('');



    const [totalRecord, setTotalRecord] = useState(0);

    const [showExtraFilters, setShowExtraFilters] = useState(false);
    const editHandleClose = (Filter?: any) => {
        setEditShow(false);
    
        let isNewestSelected = false;
        let isOldestSelected = false;
        let isPremiumTagsSelected = false;
        let isFreeTagsSelected = false;
        let isPopularitySelected = false;
        let isFollowersSelected = false;
        let isViewedSelected = false;
        let isUserSelected = false;
    
        if (Filter && Filter.length > 0) {
            const selectedFilter = Filter.map((item: any) => item.label).join(',');
            setSelectedFilter(selectedFilter);
    
            isNewestSelected = selectedFilter.includes('Newest');
            isPremiumTagsSelected = selectedFilter.includes('Premium Tags');
            isFreeTagsSelected = selectedFilter.includes('Free Tags');
            isOldestSelected = selectedFilter.includes('Oldest');
            isPopularitySelected = selectedFilter.includes('Popularity');
            isFollowersSelected = selectedFilter.includes('More Followers');
            isViewedSelected = selectedFilter.includes('Most Viewed');
            isUserSelected = selectedFilter.includes('Most Used');
        } else if (!Filter) {
            setSelectedFilter(''); 
        }
    
        // Set the values based on the consolidated conditions
        const premiumOrFree = isPremiumTagsSelected ? 'PAID' : (isFreeTagsSelected ? 'FREE' : ' ');
    
        setNewest(isNewestSelected ? 'newest' : ' ');
        setOldest(isOldestSelected ? 'oldest' : ' ');
        setPremium(premiumOrFree);
        setFree(isFreeTagsSelected && !isPremiumTagsSelected ? 'FREE' : ' ');
        setPopularity(isPopularitySelected ? 'popular' : ' ');
        setFollowers(isFollowersSelected ? 'followers' : ' ');
        setViewers(isViewedSelected ? 'viewer' : ' ');
        setUsers(isUserSelected ? 'user' : ' ');
    };
    
    
    const getProfessionalPageDetails = async () => {
        try {
            const userId = StorageService.getUserId();
            console.log(userId);
            let res = await professionalPageApiService.getProfessionalPageData(userId);
            setUserPageData(res);
            const activePageItem = res.find(item => item.activePage === true);
            const userPageId = activePageItem?.id;
            getProfessionalPageById(userPageId);
            getPageData(currentPage, pageSize);

          
        } catch (error) {
            console.log(error);
        }
    };
    const getProfessionalPageById = async (userPageId) => {
        try {
            let res = await professionalPageApiService.getProfessionalPageById(userPageId);
            setData(res);
        } catch (error) {
            console.log(error);
        }
    };
    const getProfessionalPagePostDataList = async () => {
        try {
            let res = await professionalPageApiService.getProfessionalPageData(userId);
            setProfessionalPagePosts(res);
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    };
    const handleFollowClick = (id:any) => {
        setIsFollowing((prevIsFollowing) => !prevIsFollowing);
         savePageFollow(id);
      };
      const savePageFollow = async (id:any) => {
        try {
          let payload: PagePostTagFollowPayload = {
            followingTagId: id,
            userPageId: userPageId
          };
          await professionalPageApiService.savePagePostTagFollow(payload);
          getProfessionalPageDetails();
        } catch (error) {
          console.log(error)
        }
      }
    const handleFollowButtonClick = (id:any) => {
        let user = StorageService.isLoggedIn();
        if (!user) {
          showLoggedInConfirmDialog();
        } else {
          handleFollowClick(id);
        }
      };
      const showLoggedInConfirmDialog = () => {
        confirmAlert({
            title: 'Alert',
            message: 'You have to log in before Follow ',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => { navigate('/login'); }
                },
                {
                    label: 'No',
                    onClick: () => { console.log('clicked no') }
                }
            ]
        });
      };
    
    useEffect(() => {
        getProfessionalPageDetails();
        getProfessionalPagePostDataList();
    }, []);
    const getPageData = async (page, size) => {
        try {
            let url = `?page=${page}&pageSize=${size}&tagName=${selectedTagName}&createdAt=${oldest}&popularity=${popularity}&tagType=${premium}&moreFollower=${followers}&morePost=${users}`;
            
            // let result = await professionalPageApiService.getAllPostData(url);
            let result = await professionalPageApiService.getAllTagData(url);
            console.log("sssssssssssssssssssssssssssssss", result);
            setProfessionalPagePostTags(result.data);
            setTotalRecord(result.totalRecord);
        } catch (error) {
            console.log("poperty search Error");
            console.log(error);
            if (error.response) {
                toast.error(error.response.data.message, { containerId: 'TR' });
                StorageService.clear();
                navigate('/login');
            } else if (error.request) {
                toast.error('Error: No response received from the server');
            } else {
                toast.error(`Error: ${error.message}`);
            }
        }
    }


    useEffect(() => {
        getPageData(currentPage, pageSize);
    }, [selectedTagName,oldest,popularity,premium,followers,users]);


   


  
    const handleTagFollowClick = (id: number,tagUrl:any,rank:any) => {
        navigate(`/view-profession-tag-follow?tagUrl=${tagUrl}&tagId=${id}&rank=${rank}`, { state: {} });
        console.log(`View button clicked for tagId: ${id}`);
    };


   

    const handleArrowClick = (id: number,tagUrl:any,rank:any) => {
        navigate(`/view-profession-post-tag?tagUrl=${tagUrl}&tagId=${id}&rank=${rank}`, { state: {} });
        console.log(`View button clicked for tagId: ${id}`);
    };


    const renderMenuItemChildren = (option, props) => (
        <div>

            <span>{option.tagName}</span>
        </div>
    );









    const searchTag = async (s: any) => {
        setTagSearchLoading(true);
        let data = await propertyApiService.searchTag(s);
        console.log(s);
        setTagSearchLoading(false);
        setTagData(data);
    };

    const onTagSelected = (t: any) => {
        if (t.length > 0) {
            console.log(t[0]);
            setSelectedTag(t);
            setSelectedTagId(t[0].id);
            setSelectedTagName(t[0].tagName);
        }
    };




    return (
        <>
            <Header />

            <div className="profession-post-tag">

                <div className="image-cover p-6 hero-banner mx-auto">
                    <div className="container">
                        <div className=" mt-4">
                        <div className="col-sm-10 mx-auto my-3 d-flex flex-column align-items-center justify-content-center">
    <label
        style={{
            color: 'black',
            fontSize: '20px',
            marginBottom: '16px', // added margin-bottom to create space between label and the rest of the content
            textAlign: 'center' // center-align the label text
        }}
    >
        Tags
    </label>
    <div className="d-flex justify-content-between flex-wrap col-sm-8 align-items-center">
        <div className="d-flex col-sm-9 justify-content-end align-items-center mb-3">
            <Col sm={8}>
                <Row className="tag-search-container me-3">
                    <Col className="px-0 ms-3">
                        <AsyncTypeahead
                            id="tag-search"
                            className="async-typeahead-container custom-async-typeahead rounded-pill focus"
                            labelKey="tagName"
                            onSearch={searchTag}
                            options={tagData}
                            onChange={onTagSelected}
                            useCache={false}
                            placeholder="Search Tags"
                            defaultSelected={selectedTag}
                            isLoading={tagSearchLoading}
                            renderMenuItemChildren={renderMenuItemChildren}
                        />
                    </Col>
                    <Col xs="auto" className="border-start">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Col>
                </Row>
            </Col>

            <label
                style={{
                    color: 'grey',
                    marginRight: '16px',
                    fontSize: '16px'
                }}
            >
                Filters
            </label>
          
            <span onClick={handleIconClick} style={{ fontSize: '30px', cursor: 'pointer' }} className="ms-2">
                <TuneIcon />
            </span>
        </div>
    </div>
</div>

         
<div className="col-md-7 mx-auto">
    <div className="row justify-content-between">
        {professionalPagePostTags.map((item, index) => {
            const isFollowing = item.professionalPagePostTagFollowDataList.some(follow => follow.userPageId === activePageItem?.id);
            const followersCount = item.professionalPagePostTagFollowDataList.length;
            const postsCount = item.professionalPagePostTagData.length;


            // Get the first four follower images
            const followerImages = item.professionalPagePostTagFollowDataList.slice(0, 4);
            // Calculate the number of additional followers
            const additionalFollowers = followersCount - 4;

            return index === 0 ? (
                <div className="col-md-12 mb-4" key={index}>
                    <div className="card tagsborder mx-auto">
                        <div className="row no-gutters">
                            <div className="col-lg-auto col-md-12 pe pe-lg-0">
                            <Figure className="mb-0" style={{ height: '250px', width: '100%', position: 'relative' }}>
    <img
        src={item.tagImage || DefaultImage}
        className="card-img tags-img-container"
        alt="Default Sample Image"
        style={{ width: '100%', height: '100%',objectFit:'cover' }}
    />
      {item.tagCost != null && item.tagCost !== '' && (
        <div
            style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: '#8080807a',
                position: 'absolute',
                top: '10px',
                right: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <img
                src={crown}
                alt="Profile Image"
                style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                }}
            />
        </div>
    )}
</Figure>

                            </div>
                            <div className="col-md ps-0">
                                <div className="card-body h-100 d-flex flex-column justify-content-around">
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon icon={faTag} style={{ color: 'red', fontSize: '24px', marginRight: '10px' }} />
                                        <div className="flex-grow-1 text-center">
                                            <label className="card-title" style={{ color: 'black', fontSize: '20px',fontWeight:'500' }}>{item.tagName}</label>
                                        </div>
                                    </div>
                                    <div 
    className="d-flex align-items-center justify-content-center mb-2" 
>
    <label style={{ color: 'rgb(117 113 113)', marginRight: '10px', fontSize: '16px', cursor: 'pointer' }}
        onClick={() => handleArrowClick(item.id, item.tagUrl, item.rank)}
        >
        {postsCount} Posts
    </label>

    {followerImages.map((followItem, imgIndex) => (
        <img
            key={imgIndex}
            src={followItem.profileImageUrl || person}
            className="card-img-top rounded-circle"
            alt={`Follower ${imgIndex + 1}`}
            style={{
                width: '100%',
                maxWidth: '35px',
                height: '35px',
                position: 'relative',
                marginLeft: imgIndex === 0 ? '0' : '-20px',
                zIndex: imgIndex, // Higher zIndex for images on the right
            }}
        />
    ))}

    <label style={{ color: 'rgb(117 113 113)', marginLeft: '10px', fontSize: '16px',cursor: 'pointer' }}
    onClick={() => handleTagFollowClick(item.id, item.tagUrl, item.rank)}>
        {followersCount} Followers
    </label>
</div>

                                    <div className="text-center">
                                        <button
                                            style={{
                                                borderRadius: '20px',
                                                padding: '1px 30px',
                                                backgroundColor: 'transparent',
                                                color: '#009de2',
                                                border: '2px solid lightblue',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => handleFollowButtonClick(item.id)}
                                        >
                                            {isFollowing ? 'Following' : 'Follow'}
                                        </button>
                                        <FontAwesomeIcon
                                                            icon={faArrowRight}
                                                            style={{ color: 'grey', fontSize: '35px', float: 'right', cursor: 'pointer' }}
                                                            onClick={() => handleArrowClick(item.id, item.tagUrl,item.rank)}

                                                        />                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="col-lg-5 mb-4" key={index}>
                    <div className="card tagsborder mx-auto">
                    <Figure className="mb-0" style={{ height: '180px', position: 'relative' }}>
    <img
        src={item.tagImage || DefaultImage}
        className="card-img-top"
        alt="Default Sample Image"
        style={{ width: '100%', height: '100%', borderRadius: '20px 19px 0px 0px',objectFit:'cover' }}
    />
    {item.tagCost != null && item.tagCost !== '' && (
        <div
            style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: '#8080807a',
                position: 'absolute',
                top: '10px',
                right: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <img
                src={crown}
                alt="Profile Image"
                style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                }}
            />
        </div>
    )}
</Figure>


                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <FontAwesomeIcon icon={faTag} style={{ color: 'red', fontSize: '24px', marginRight: '10px' }} />
                                <div className="flex-grow-1 text-center">
    <label className="card-title" style={{ color: 'black', fontSize: '20px' ,marginLeft:'-32px'}}>
        {item.tagName.length > 15 ? item.tagName.substring(0, 15) + '...' : item.tagName}
    </label>
</div>

                            </div>
                            <div className="text-center mx-auto imagefollow  py-1">
                                <label style={{ color: 'rgb(117 113 113)', whiteSpace: 'nowrap', marginLeft: '29px', cursor: 'pointer' }}
                                    onClick={() => handleArrowClick(item.id, item.tagUrl, item.rank)}
                                    >{postsCount} Posts</label>

                                {followerImages.map((followItem, imgIndex) => (
    <img
        key={imgIndex}
        src={followItem.profileImageUrl || person}
        className="card-img-top rounded-circle"
        alt={`Follower ${imgIndex + 1}`}
        style={{
            width: '100%',
            maxWidth: '30px',
            height: '30px',
            position: 'relative',
            marginLeft: imgIndex === 0 ? '0' : '-18px',
            zIndex: imgIndex, // Higher zIndex for images on the right
        }}
    />
))}


                                <label style={{ color: 'rgb(117 113 113)', whiteSpace: 'nowrap' ,marginRight:'43px'}}
                                    onClick={() => handleTagFollowClick(item.id, item.tagUrl, item.rank)}>
                                    {followersCount} Followers</label>
                            </div>
                            <div className="text-center py-1 card-body">
                                <button
                                    style={{
                                        borderRadius: '20px',
                                        padding: '1px 15px',
                                        backgroundColor: 'transparent',
                                        color: '#009de2',
                                        border: '2px solid lightblue',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleFollowButtonClick(item.id)}
                                >
                                    {isFollowing ? 'Following' : 'Follow'}
                                </button>
                                <FontAwesomeIcon
                                                            icon={faArrowRight}
                                                            style={{ color: 'grey', fontSize: '25px', float: 'right', cursor: 'pointer' }}
                                                            onClick={() => handleArrowClick(item.id, item.tagUrl,item.rank)}
                                                            />                            </div>
                              
                        </div>
                    </div>
                </div>
            );
        })}
    </div>
</div>




                        </div>
                    </div>

                </div>
            </div>
            <Modal  size="sm"  show={editShow} onHide={editHandleClose}>
                            <TagSearch handleClose={editHandleClose} />
                        </Modal>
                       
        </>
    );



};
export default ProfessionPostTag;
