import { Fragment, useEffect, useRef, useState } from "react";
import "./add-profession-post.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import FormFieldError from "../../../components/form-field-error/form-field-error";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import PropertyApiService from "../../../data/api/services/property/property-api-service";
import ValidationError from "../../../common/validation-error";
import { Carousel, Col, Row } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import StorageService from "../../../data/storage/storage-service";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddProfessionPost = (props: any) => {
    const professionalPageApiService = new ProfessionalPageApiService();
    const propertyApiService = new PropertyApiService();
    const userType = StorageService.getUserType();
    const properyCoverImageInput: any = useRef(null);
    const maxLength = 1000;
    const minLength = 0;
    let navigate: any = useNavigate();
    const [locationSearchLoading, setLocationSearchLoading] = useState(false);
    const [locationData, setLocationData] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState<any>([]);
    const [callToActionData, setCallToActionData] = useState([]);
    const [callToActionIdStr, setCallToActionIdStr] = useState("");
    const [showCallToActionOption, setShowCallToActionOption] = useState(true);
    const [description, setDescription] = useState('');
    const [callToActionUrl, setCallToActionUrl] = useState('');
    const [postImage, setPostImage] = useState<any>([]);
    const postImageInput: any = useRef(null);
    const [index, setIndex] = useState(0);
    const [locationValidationErrorMsg, setLocationValidationNameErrorMsg] = useState(null);
    const [callToActionValidationErrorMsg, setCallToActionValidationErrorMsg] = useState(null);
    const [professionalPagePostFormData, setProfessionalPagePostFormData] = useState<any>(new FormData());
    const [descriptionValidationErrorMsg, setDescriptionValidationErrorMsg] = useState(null);
    const [callToActionUrlValidationErrorMsg, setCallToActionUrlValidationErrorMsg] = useState(null);
    const [postImageValidationErrorMsg, setPostImageValidationErrorMsg] = useState(null);
    const [userPageData, setUserPageData] = useState<any>([]);
    const activePageItem = userPageData.find(item => item.activePage === true);
    const userPageId = activePageItem?.id;
    const [tagInput, setTagInput] = useState<string>('');
    const [tags, setTags] = useState<string[]>([]);
    const [hoveredTag, setHoveredTag] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [postTagsTemplateData, setPostTagsTemplateData] = useState([]);

    const handleCallToActionChange = (btId: any) => {
        setCallToActionIdStr(btId);
        setShowCallToActionOption(false);
    };

    const handleDescriptionOnChange = (ds: any) => {
        setDescription(ds);
    };

    const handlecallToActionUrlOnChange = (cu: any) => {
        setCallToActionUrl(cu);
    };

    const onUploadPostListener = (event: any) => {
        let images: any = [];
        for (let i = 0; i < event.target.files.length; i++) {
            let image: any = {};
            image.file = event.target.files[i];
            image.url = URL.createObjectURL(image.file);
            images.push(image);
        }
        setPostImage(prevImages => [...prevImages, ...images]);
        setPostImageValidationErrorMsg('');
    }

    const onProfessionalPageUploadPostListener = (event: any) => {
        const files = event.target.files;
        if (files.length > 0) {
            let mediaFiles: any = [];
            for (let i = 0; i < files.length; i++) {
                let media: any = {};
                media.file = files[i];
                if (media.file.type.startsWith('image/')) {
                    media.imagePath = URL.createObjectURL(media.file);
                    media.type = 'image';
                } else if (media.file.type.startsWith('video/')) {
                    media.videoPath = URL.createObjectURL(media.file);
                    media.type = 'video';
                }
                mediaFiles.push(media);
            }
            setPostImage(prevImages => [...prevImages, ...mediaFiles]);
        } else {
            setPostImage([]);
        }
    };

    const clearCoverImage = (index: number) => {
        const updatedImages = [...postImage];
        updatedImages.splice(index, 1);
        setPostImage(updatedImages);
    }

    const validateUploadPostFormData = (): void => {
        let errors: any[] = [];

        if (selectedLocation.length === 0) {
            let error: any = {};
            error.validationCode = "taggedLocationId";
            error.message = 'Location required';
            errors.push(error);
        }

        if (postImage.length <= 0) {
            let error: any = {};
            error.validationCode = "mediaUrl";
            error.message = 'Image required';
            errors.push(error);
        }

        if (errors.length > 0) {
            throw new ValidationError(errors);
        }
    }

    const showValidationErrorInlineUI = (error: ValidationError) => {
        let errors: any[] = error.errors;
        for (let i = 0; i < errors.length; i++) {
            let error: any = errors[i];
            let errorCode: any = error.validationCode;
            let errorMsg: any = error.message;

            if (errorCode === 'mediaUrl') {
                setPostImageValidationErrorMsg(errorMsg);
            }
            if (errorCode === 'taggedLocationId') {
                setLocationValidationNameErrorMsg(errorMsg);
            }
        }
    }

    const validateAndUploadPostFormData = () => {
        try {
            validateUploadPostFormData();
            if (professionalPagePostFormData.has("mediaUrl")) {
                professionalPagePostFormData.delete('mediaUrl');
            } for (let i = 0; i < postImage.length; i++) {
                professionalPagePostFormData.append('mediaUrl', postImage[i].file);
            }

            if (professionalPagePostFormData.has('callToAction')) {
                professionalPagePostFormData.delete('callToAction');
            }
            professionalPagePostFormData.append('callToAction', callToActionIdStr);

            if (professionalPagePostFormData.has("professionalPostId")) {
                professionalPagePostFormData.delete('professionalPostId');
            }
            professionalPagePostFormData.append('professionalPostId', userPageId);

            if (professionalPagePostFormData.has('taggedLocationId')) {
                professionalPagePostFormData.delete('taggedLocationId');
            }
            if (selectedLocation.length > 0) {
                professionalPagePostFormData.append('taggedLocationId', selectedLocation[0].id);
            }

            if (professionalPagePostFormData.has('caption')) {
                professionalPagePostFormData.delete('caption');
            }
            professionalPagePostFormData.append('caption', description);
            if (professionalPagePostFormData.has('callToActionUrl')) {
                professionalPagePostFormData.delete('callToActionUrl');
            }
            professionalPagePostFormData.append('callToActionUrl', callToActionUrl);

            if (professionalPagePostFormData.has("tags")) {
                professionalPagePostFormData.delete('tags');
            }
            professionalPagePostFormData.append('tags', concatenatedTags);

        } catch (error: any) {
            throw error;
        }
    }

    const uploadProfessionalPagePost = async () => {
        try {
            validateAndUploadPostFormData();
            setLoading(true);
            let data = await professionalPageApiService.uploadProfessionalPagePost(professionalPagePostFormData);
            console.log(data);
            toast.success('Uploaded Post Successfully', { containerId: 'TR' });
            window.location.reload();
        } catch (error) {
            showValidationErrorInlineUI(error);
        }
    };

    const searchLocation = async (q: any) => {
        setLocationSearchLoading(true);
        let data = await propertyApiService.searchLocation(q);
        console.log(q);
        setLocationSearchLoading(false);
        formatLocationData(data);
    };

    const formatLocationData = (data: any) => {
        formatLocationDataResponse(data);
        setLocationData(data);
    };

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const formatLocationDataResponse = (data: any) => {
        for (let i = 0; i < data.length; i++) {
            data[i].nameLabel = data[i].name + ' | ' + data[i].pinCode;
        }
    };

    const onLocationSelected = (e: any) => {
        if (e.length > 0) {
            console.log(e[0]);
            setSelectedLocation(e);
        }
        setLocationValidationNameErrorMsg('');
    };

    const clearSelectedLocation = () => {
        setSelectedLocation([]);
    };

    const getTemplateData = async () => {
        let data = await professionalPageApiService.getProfessionalPageTemplateData();
        console.log(data);
        setCallToActionData(data.callToActionData);
    };

    const getProfessionalPageDetails = async () => {
        try {
            const userId = StorageService.getUserId();
            console.log(userId);
            let res = await professionalPageApiService.getProfessionalPageData(userId);
            setUserPageData(res);
        } catch (error) {
            console.log(error);
        }
    };

    const getProfessionalPagePostTagTemplateData = async () => {
        let data = await professionalPageApiService.getProfessionalPagePostTagTemplateData();
        console.log(data);
        setPostTagsTemplateData(data);
    };

    useEffect(() => {
        getProfessionalPageDetails();
        getTemplateData();
        getProfessionalPagePostTagTemplateData();
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTagInput(event.target.value);
    };

    const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === ',' || event.key === 'Enter') {
            event.preventDefault();
            addTag();
        }
    };
    const renderMenuItemChildren = (option, props, index) => {

        return (
            <div key={index}>
                <span style={{ color: 'black' }}>{option.name}</span>
                {' | '}
                <span style={{ color: 'blue' }}>{option.pinCode}</span>
                {' | '}
                <span style={{ color: 'red' }}>{option.postOfficeName}</span>
                {' | '}
                <span style={{ color: 'green' }}>{option.talukaName}</span>
                {' | '}
                <span style={{ color: 'orange' }}>{option.districtName}</span>
                {' | '}
                <span style={{ color: '#fa0acc' }}>{option.abbreviation}</span>

            </div>
        );
    };
    const addTag = () => {
        if (tagInput.trim() !== '') {
            if (!tags.includes(tagInput.trim())) {
                setTags([...tags, tagInput.trim()]);
            }
            setTagInput('');
        }
    };

    const concatenatedTags = tags.join(',');

    const removeTag = (tagToRemove: string) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
    };

    return <>
        <div style={{ position: 'relative' }}>
            {loading && (
                <div className="d-flex justify-content-center align-items-center"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        zIndex: 10
                    }}>
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            )}
            <div className="p-3">
                <div className="d-flex justify-content-between mb-1">
                    <div>
                        <button className="btn" onClick={props.handleClose} ><ArrowBackIcon /></button>
                        <span className="ms-3 fs-5 fw-bold">Add Post</span>
                    </div>
                    <div>
                        <button className="btn" onClick={props.handleClose} ><CloseIcon /></button>
                    </div>
                </div>
                {userType === "ROLE_PRO_USER" && (
                    <div className="row mt-3">
                        <div className="col-sm-6 mb-3">
                            <label className="mb-2">Call To Action</label>
                            <select className={callToActionValidationErrorMsg ? ' call-to-action error-field input-edit form-control' : 'call-to-action input-edit form-control'}
                                value={callToActionIdStr}
                                onChange={(e) => handleCallToActionChange(e.target.value)}>
                                {showCallToActionOption && <option>Select </option>}
                                {callToActionData && callToActionData.map((ctaData, index) => (<option className="text-edit" key={ctaData.id} value={ctaData.id}>{ctaData.label}</option>))}
                            </select>
                            <FormFieldError errorMessage={callToActionValidationErrorMsg}></FormFieldError>
                        </div>
                        <div className="col-sm-6 mb-3">
                            <label className="mb-2">Call To Action Url</label>
                            <input
                                type="text"
                                className={`call-to-action input-value form-control p-3 ${callToActionUrlValidationErrorMsg ? 'error-field input-edit' : ''}`}
                                placeholder="Call To Action Url"
                                value={callToActionUrl}
                                minLength={minLength}
                                maxLength={maxLength}
                                onChange={e => { handlecallToActionUrlOnChange(e.target.value) }}
                            />
                            <FormFieldError errorMessage={callToActionUrlValidationErrorMsg}></FormFieldError>
                        </div>
                    </div>
                )}
                <div className="row mt-3">
                    <Col className="mb-2">
                        <textarea
                            className={`input-value form-control p-3 ${descriptionValidationErrorMsg ? 'error-field input-edit' : ''}`}
                            placeholder="Add description"
                            value={description}
                            minLength={minLength}
                            maxLength={maxLength}
                            onChange={e => { handleDescriptionOnChange(e.target.value) }}
                            rows={3} cols={28}>
                        </textarea>
                        <FormFieldError errorMessage={descriptionValidationErrorMsg}></FormFieldError>
                        <div className='under-text m-0 mt-2'>
                            <div className="d-flex justify-content-between">
                                <span>Remaining:</span>
                                <span>{minLength + description.length}/1000</span>
                            </div>
                        </div>
                    </Col>
                </div>
                <div className="row mt-3">
                    <Col className="mb-2">
                        <label className="mb-2">Tag</label>
                        <div className="tag-container">
                            {tags.map(tag => (
                                <div key={tag} className="tag" style={hoveredTag === tag ? { border: '#ff5c5c solid 3px' } : {}}>
                                    <span className="tag-text">{tag}</span>
                                    <button className="tag-remove-btn px-1"
                                        onMouseEnter={() => setHoveredTag(tag)}
                                        onMouseLeave={() => setHoveredTag(null)}
                                        onClick={() => removeTag(tag)}>X</button>
                                </div>
                            ))}
                        </div>
                        <input
                            type="text"
                            className="form-control p-3"
                            value={tagInput}
                            onChange={handleInputChange}
                            onKeyPress={handleInputKeyPress}
                            list="ctaOptions"
                            placeholder="Add tags and press Enter"
                        />
                        <datalist id="ctaOptions" style={{ maxHeight: '20px' }}>
                            {postTagsTemplateData && postTagsTemplateData.map((tagData) => (
                                <option key={tagData.id} value={tagData.tagName} />
                            ))}
                        </datalist>
                        <button className="btn btn-primary mt-2 rounded-pill" onClick={addTag}>Add</button>
                    </Col>
                </div>
                <div className="row mt-3">
                    <div className="col-sm-6 mb-5">
                        <label className="mb-2">Tag Location<sup className="required">*</sup></label>
                        <div>
                            <Col className="justify-content-end align-items-center d-flex async-container">
                                <Col xs="auto" className="close-icon">
                                    {selectedLocation.length > 0 && (
                                        <FontAwesomeIcon icon={faTimes} onClick={clearSelectedLocation} />
                                    )}
                                </Col>
                                <Col>
                                    <AsyncTypeahead
                                        className={`test ${locationValidationErrorMsg ? 'error-field input-edit' : ''}`}
                                        labelKey="formatedLocationData"
                                        onSearch={(e) => searchLocation(e)}
                                        options={locationData}
                                        onChange={onLocationSelected}
                                        useCache={false}
                                        placeholder="Search Location"
                                        defaultSelected={selectedLocation}
                                        isLoading={locationSearchLoading}
                                        selected={selectedLocation}
                                        renderMenuItemChildren={renderMenuItemChildren}
                                    />
                                </Col>
                            </Col>
                            <FormFieldError errorMessage={locationValidationErrorMsg}></FormFieldError>
                        </div>
                        <div className='below-text mt-3 m-0'>
                            {selectedLocation.length > 0 && (
                                <button onClick={clearSelectedLocation} className="btn btn-danger mb-2 rounded-pill" type="button">
                                    Clear
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-6 text-center mb-3">
                        <div>
                            <input
                                type="file"
                                className="visually-hidden"
                                ref={properyCoverImageInput}
                                onChange={onProfessionalPageUploadPostListener}
                                multiple
                                accept="image/,video/"
                                id="fileInput"
                            />
                            <label
                                htmlFor="fileInput"
                                className="img-box text-primary custom-file-input"
                                onClick={onUploadPostListener}
                            >
                                <AddAPhotoIcon /> <span className="ms-2"> Add Photos</span>
                            </label>
                            <input
                                type="button"
                                className="border rounded border-dark btn-height-size"
                                onClick={onUploadPostListener}
                            />
                            <FormFieldError errorMessage={postImageValidationErrorMsg}></FormFieldError>
                        </div>
                        <div>
                            <Carousel activeIndex={index}
                                onSelect={handleSelect}
                                interval={null}
                                indicators={postImage.length > 1}
                                controls={postImage.length > 1}
                                prevIcon={<FaChevronLeft style={{ color: '#0b7ec1', fontSize: '2em', background: 'aliceblue' }} />}
                                nextIcon={<FaChevronRight style={{ color: '#0b7ec1', fontSize: '2em', background: 'aliceblue' }} />}>
                                {postImage.length > 0 &&
                                    postImage.map((postImg, index) => (
                                        <Carousel.Item key={'post_image_' + index}>
                                            <Row className="">
                                                <div className="col-sm-12 d-flex align-items-center justify-content-center">
                                                    {postImg.type === 'image' ? (
                                                        <img src={postImg.imagePath} width={'100%'} height={'200px'} />
                                                    ) : (
                                                        <>
                                                            <video width={'100%'} height={'200px'}>
                                                                <source src={postImg.videoPath} type={postImg.file.type} />
                                                            </video>
                                                            <div className="d-flex align-items-center justify-content-center video-play-icon">
                                                                <PlayCircleOutlineIcon style={{ fontSize: 'xxx-large' }} />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="col-sm-12 d-flex justify-content-start">
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-sm rounded-pill mt-2"
                                                        aria-label="Close"
                                                        onClick={() => clearCoverImage(index)}
                                                    >
                                                        <span className="" aria-hidden="true">
                                                            Remove
                                                        </span>
                                                    </button>
                                                </div>
                                            </Row>
                                        </Carousel.Item>
                                    ))}
                            </Carousel>
                        </div>
                    </div>
                    <div className="text-end p-3">
                        <button className="btn btn-success" onClick={uploadProfessionalPagePost} type="button">Upload Post</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default AddProfessionPost;