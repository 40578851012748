import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";
import { lazy, Suspense } from "react";
import EditPropertyContainer from "../pages/property/edit-property-container";
import AddLoanContainer from "../pages/loan-page/add-loan-container/add-loan-container";
import About from "../layouts/about/about";
import WorkFlow from "../layouts/footer/work-flow/work-flow";
import AboutPage from "../layouts/about-page/about-page";
import ViewProfessionProfileContainer from "../pages/profession/view-profession-profile-container";
import ViewProfessionImage from "../pages/profession/view-profession-image/view-profession-image";
import CreateProfessionalPageContainer from "../pages/profession/create-professional-page/create-professional-page-container";
import ViewProfessionVideo from "../pages/profession/view-profession-video/view-profession-video";
import RegisterPageContainer from "../pages/register/register-page-container";
import MonthlyQuestions from "../pages/monthly-questions/monthly-questions";
import AnnualQuestions from "../pages/annual-questions/annual-questions";
import VeeduBenefits from "../pages/veedu-benefits/veedu-benefits";
import Contact from "../layouts/about/contact";
import UserTypeWidget from "../widgets/user-type/user-type-widget";
import ContactUs from "../layouts/about/contact-us";
import EditProfessioanlPageBasicinfo from "../pages/profession/create-professional-page/edit-professional-page-basic-info/edit-professional-page-basic-info";
import EditProfessionalPageContactInfo from "../pages/profession/create-professional-page/edit-professionalpage-contact-info/edit-professionalpage-contact-info";
import EditProfessionalPageService from "../pages/profession/create-professional-page/edit-professionalpage-service/edit-professionalpage-service";
import EditProfessionalPageServiceArea from "../pages/profession/create-professional-page/edit-professional-page-service-area/edit-professional-page-service-area";
import UpdateProfessionalPageContainer from "../pages/profession/create-professional-page/update-professional-page-container";
import EditProfessionPost from "../pages/profession/edit-profession-post/edit-profession-post";
import MyProfessionalPages from "../pages/profession/my-professional-pages/my-professional-pages";
import ProfessionalPageUsers from "../pages/profession/professional-page-users/professional-page-users";
import ProfessionalPageMessage from "../pages/profession/professional-page-message/professional-page-message";
import ViewProfessionalPageSinglePost from "../pages/profession/view-professional-page-single-post/view-professional-page-single-post";
import Forgotpassword from "../pages/forget-password/forgot-password";
import Changepassword from "../pages/change-password/change-password";
import ProfessionPostTag from "../pages/profession/profession-post-tag/profession-post-tag";
import Pricing from "../pages/veedu-benefits/pricing";
import ViewProfessionPost from "../pages/profession/view-profession-post/view-profession-post";
import ViewProfessionPostTag from "../pages/profession/view-profession-post-tag/view-profession-post-tag";
import ViewProfessionTagFollow from "../pages/profession/view-profession-tag-follow/view-profession-tag-follow";
const LoginPage = lazy(() => import('../pages/login/login-page'));
const AddPropertyContainer = lazy(() => import('../pages/property/add-property-container'));
const PropertyListContainer = lazy(() => import('../pages/property/property-list-page/property-list-container'));
const PropertySearchResult = lazy(() => import('../pages/property-search-result/property-search-result'));
const HomePage = lazy(() => import('../pages/home/home-page'));
const AddLocationPage = lazy(() => import('../pages/property/add-location-page/add-location-page'));
const ViewPropertyDetaiList = lazy(() => import('../pages/property-details/view-property-details/view-property-details'));
const ViewLoanStatus = lazy(() => import('../pages/loan-history/view-loan-status/view-loan-status'));
const EditLocationPage = lazy(() => import('../pages/property/edit-location-page/edit-location-page'));
const Auth = lazy(() => import('../pages/login/auth'));
const PaymentProcessingPage = lazy(() => import('../pages/veedu-benefits/payment-processing-page'));

const AppRouter = () => {
    return <>
        <Suspense fallback={<span>Loading....</span>}>
            <Router>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/forgotPassword" element={<Forgotpassword />} />
                    <Route path="/changePassword" element={<Changepassword />} />
                    <Route path="/aboutUs" element={<AboutPage />} />
                    <Route path="/aboutContact" element={<Contact />} />
                    <Route path="/contact" element={<ContactUs />} />
                    {/* <Route path="/pricing" element={<VeeduBenefits registrationPayload={undefined} onPreviousCallback={undefined} />} /> */}
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/loan" element={<AddLoanContainer />} />
                    <Route path="/property-list" element={<PropertyListContainer />} />
                    <Route path="/" element={<HomePage />} />
                    <Route path="/register" element={<RegisterPageContainer />} />
                    <Route path="/view-property" element={<ViewPropertyDetaiList />} />
                    <Route path="/add-property" element={<AddPropertyContainer />} />
                    <Route path="/edit-property-page" element={<EditPropertyContainer />} />
                    <Route path="/about" element={<About aboutType={undefined} />} />
                    <Route path="/privacy-policy" element={<About aboutType={'PRIVACY_POLICY'} />} />
                    <Route path="/terms-of-use" element={<About aboutType={'TERMS_OF_USE'} />} />
                    <Route path="/refund-policy" element={<About aboutType={'REFUND_POLICY'} />} />
                    <Route path="/how-it-work" element={<WorkFlow />} />
                    <Route path="/property" element={<PropertySearchResult />} />
                    <Route path="/loan-status" element={<ViewLoanStatus />} />
                    <Route path="/location" element={<AddLocationPage propertyFormData={undefined} onNextCallback={undefined} onPreviousCallback={undefined} />} />
                    <Route path="/edit-location" element={<EditLocationPage propertyFormData={undefined} onNextCallback={undefined} onPreviousCallback={undefined} deedType={undefined} propertyId={undefined} />} />
                    <Route path="/view-profession-profile" element={<ViewProfessionProfileContainer />} />
                    <Route path="/view-profession-image" element={<ViewProfessionImage />} />
                    <Route path="/view-profession-video" element={<ViewProfessionVideo />} />
                    <Route path="/create-professional-page" element={<CreateProfessionalPageContainer />} />
                    <Route path="/update-professional-page" element={<UpdateProfessionalPageContainer />} />
                    <Route path="/update-professional-page-post" element={<EditProfessionPost />} />
                    <Route path="/edit-profession-post" element={<EditProfessionPost />} />
                    {/* <Route path="/free-questions" element={<FreeQuestions />} /> */}
                    <Route path="/monthly-questions" element={<MonthlyQuestions />} />
                    <Route path="/annual-questions" element={<AnnualQuestions />} />
                    <Route path="/UserTypeWidget" element={<UserTypeWidget />} />
                    <Route path="/my-professional-pages" element={<MyProfessionalPages />} />
                    <Route path="/professionals" element={<ProfessionalPageUsers />} />
                    <Route path="/professional-page-message" element={<ProfessionalPageMessage />} />
                    <Route path="/view-professional-page-post" element={<ViewProfessionalPageSinglePost />} />
                    <Route path="/tags" element={<ProfessionPostTag />} />
                    <Route path="/view-profession-post-tag" element={<ViewProfessionPostTag />} />
                    <Route path="/view-profession-tag-follow" element={<ViewProfessionTagFollow />} />

                    <Route path="/auth" element={<Auth />} />
                    <Route path="/payment-processing" element={<PaymentProcessingPage />} />
                </Routes>
            </Router>
        </Suspense>
    </>
}

export default AppRouter;