import "../view-profession-post/view-profession-post.css";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { Modal, Button, Col, Row, Carousel } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import AddProfessionPost from "../add-profession-post/add-profession-post";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ProfessionalPageApiService from "../../../data/api/services/professional-page/professional-page-api-service";
import StorageService from "../../../data/storage/storage-service";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { faBookmark, faEdit, faFlag, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import EditProfessionPost from "../edit-profession-post/edit-profession-post";
import SendIcon from '@mui/icons-material/Send';
import RecommendIcon from '@mui/icons-material/Recommend';
import { CommentLikePayload, CommentReplyLikePayload, FavouritePostPayload, PagePostCommentPayload, PagePostCommentReplyPayload, PagePostLikePayload } from "../../../data/api/services/professional-page/professional-page-payload";
import { Tooltip } from 'react-tooltip'
import ProfessionPostReport from "../profession-post-report/profession-post-report";
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import ProfessionPostShare from "../profession-post-share/profession-post-share";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Avatar from "../../../assets/profile-pic.jpeg"
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import LoginPageDialog from "../../login/login-page-dialog";

const ViewProfessionPost = ({ professionalPageId }: { professionalPageId: number }) => {

  let navigate: any = useNavigate();
  const professionalPageApiService = new ProfessionalPageApiService();
  const [data, setData] = useState<any>([]);
  const [editData, setEditData] = useState<any>([]);
  const [postId, setPostId] = useState<any>([]);
  const [reportData, setReportData] = useState<any>([]);
  const [shareData, setShareData] = useState<any>([]);
  const [professionalPagePosts, setProfessionalPagePosts] = useState<any>([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [editShow, setEditShow] = useState(false);
  const [reportShow, setReportShow] = useState(false);
  const [shareShow, setShareShow] = useState(false);
  const editHandleClose = () => setEditShow(false);
  const reportClose = () => setReportShow(false);
  const shareClose = () => setShareShow(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [comment, setComment] = useState([]);
  const [showCommentSection, setShowCommentSection] = useState(null);
  const [likedPosts, setLikedPosts] = useState([]);
  const [showReplyInput, setShowReplyInput] = useState(null);
  const [replyComment, setReplyComment] = useState([]);
  const [showChildReplyInput, setShowChildReplyInput] = useState([]);
  const [replyChildComment, setReplyChildComment] = useState([]);
  const [hasAddPostOption, setHasAddPostOption] = useState(false);
  const [userPageData, setUserPageData] = useState<any>([]);
  const activePageItem = userPageData.find(item => item.activePage === true);
  const userPageId = activePageItem?.id;
  const [isSaved, setIsSaved] = useState([]);
  const [visibleReplies, setVisibleReplies] = useState([]);
  const [commentReply, setCommentReply] = useState<any>([]);
  const [visibleChildReplies, setVisibleChildReplies] = useState([]);
  const [commentChildReply, setCommentChildReply] = useState<any>([]);
  const [selectedItemCommentIndex, setSelectedItemCommentIndex] = useState(null);
  const [isCommentDropdownVisible, setCommentDropdownVisible] = useState(false);
  const [selectedItemReplyIndex, setSelectedItemReplyIndex] = useState(null);
  const [isReplyDropdownVisible, setReplyDropdownVisible] = useState(false);
  const [reportCommentData, setReportCommentData] = useState<any>([]);
  const [reportCommentShow, setReportCommentShow] = useState(false);
  const reportCommentHandleClose = () => setReportCommentShow(false);
  const [reportCommentReplyData, setReportCommentReplyData] = useState<any>([]);
  const [reportCommentReplyShow, setReportCommentReplyShow] = useState(false);
  const reportCommentReplyHandleClose = () => setReportCommentReplyShow(false);
  const [showLogInDialog, setShowLogInDialog] = useState(false);
  const handleShowLogInDialog = () => setShowLogInDialog(true);
  const handleCloseLogInDialog = () => setShowLogInDialog(false);

  const [isExpanded, setIsExpanded] = useState([]);
  const videoRefs = useRef([]);
  const [playingVideoIndex, setPlayingVideoIndex] = useState(null);

  const handleReadMore = (index) => {
    setIsExpanded((prev) => {
      const newExpandedState = [...prev];
      newExpandedState[index] = !newExpandedState[index];
      return newExpandedState;
    });
  };

  const handlePlay = (imageDataId) => {
    if (playingVideoIndex !== null && videoRefs.current[playingVideoIndex]) {
      videoRefs.current[playingVideoIndex].pause();
    }
    setPlayingVideoIndex(imageDataId);
  };

  const handlePause = (imageDataId) => {
    if (playingVideoIndex === imageDataId) {
      setPlayingVideoIndex(null);
    }
  };

  const handlePlayIconClick = (e, imageDataId) => {
    e.stopPropagation();
    if (videoRefs.current[imageDataId]) {
      videoRefs.current[imageDataId].play();
    }
  };

  const handleLikeCommentClick = (item: any, index: any) => {
    saveCommentLike(item)
  };

  const saveCommentLike = async (item) => {
    try {
      let payload: CommentLikePayload = {
        userPageId: userPageId,
        commentId: item.id
      };
      await professionalPageApiService.saveCommentLike(payload);
      getProfessionalPageById('');
    } catch (error) {
      console.log(error)
    }
  }

  const handleViewRepliesClick = (commentId, commentIndex, item) => {
    try {
      const updatedVisibleReplies = Array(item.professionalPageCommentData.length).fill(false);
      updatedVisibleReplies[commentIndex] = !visibleReplies[commentIndex];
      setVisibleReplies(updatedVisibleReplies);
      getProfessionalPagePostCommentReplyByCommentId(commentId);
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewChildRepliesClick = (commentReply, commentReplyIndex, childArrayData: any[]) => {
    try {
      const updatedVisibleReplies = Array(childArrayData.length).fill(false);
      setVisibleChildReplies(prevState => ({
        ...updatedVisibleReplies,
        [commentReply.id]: !prevState[commentReply.id],
      }));
      getProfessionalPagePostCommentReplyByParentId(commentReply, commentReplyIndex);
    } catch (error) {
      console.log(error);
    }
  };

  const getProfessionalPagePostCommentReplyByParentId = async (commentReply, commentReplyIndex) => {
    try {
      let res = await professionalPageApiService.getProfessionalPagePostCommentReplyByParentId(commentReply.id);
      setCommentChildReply(res);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const getProfessionalPagePostCommentReplyByCommentId = async (commentId) => {
    try {
      let res = await professionalPageApiService.getProfessionalPagePostCommentReplyByCommentId(commentId);
      setCommentReply(res);
      console.log(res, 'commentReply');
    } catch (error) {
      console.log(error);
    }
  };

  const handleLikeReplyCommentClick = (commentReply, commentReplyIndex) => {
    saveCommentReplyLike(commentReply, commentReplyIndex)
  };

  const saveCommentReplyLike = async (item, index) => {
    try {
      let payload: CommentReplyLikePayload = {
        userPageId: userPageId,
        commentReplyId: item.id
      };
      await professionalPageApiService.saveCommentReplyLike(payload);
      getProfessionalPageById('');
      getProfessionalPagePostCommentReplyByCommentId(item.commentId);
    } catch (error) {
      console.log(error)
    }
  }

  const handleIsSavedClick = (item: any, index: any) => {
    setIsSaved((prevIsSaved) => {
      const newIsSaved = [...prevIsSaved];
      newIsSaved[index] = !newIsSaved[index];
      return newIsSaved;
    });
    saveFavouritePost(item, index);
  };

  const handleLikeReplyChildCommentClick = (commentReply, commentReplyIndex, replyId) => {
    saveCommentReplyChildLike(commentReply, commentReplyIndex, replyId)
  };

  const saveCommentReplyChildLike = async (item, index, replyId) => {
    try {
      let payload: CommentReplyLikePayload = {
        userPageId: userPageId,
        commentReplyId: item.id
      };
      await professionalPageApiService.saveCommentReplyLike(payload);
      getChildCommentReplyByParentId(replyId);
    } catch (error) {
      console.log(error)
    }
  }

  const getChildCommentReplyByParentId = async (replyId) => {
    try {
      let res = await professionalPageApiService.getProfessionalPagePostCommentReplyByParentId(replyId);
      setCommentChildReply(res);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const saveChildCommentReply = async (index, item, replyId) => {
    try {
      let payload: PagePostCommentReplyPayload = {
        comments: replyChildComment[index],
        commentId: item.commentId,
        postId: item.pagePostId,
        pageId: userPageId,
        commentReplyId: item.id
      };
      await professionalPageApiService.saveProfessionalPagePostCommentReply(payload);
      handleChildReplyCommentOnChange(index, '');
      getChildCommentReplyByParentId(replyId);
    } catch (error) {
      console.log(error)
    }
  }

  const handleCommentOnChange = (index: any, c: any) => {
    const newComment = [...comment];
    newComment[index] = c;
    setComment(newComment);
  };

  const handleReplyCommentOnChange = (index: any, rc: any) => {
    const newComment = [...replyComment];
    newComment[index] = rc;
    setReplyComment(newComment);
  };

  const handleChildReplyCommentOnChange = (index: any, rcc: any) => {
    const newComment = [...replyChildComment];
    newComment[index] = rcc;
    setReplyChildComment(newComment);
  };

  const getBackgroundColor = (label) => {
    switch (label) {
      case 'Shop Now':
        return 'rgb(0, 123, 255)';
      case 'Book Now':
        return 'rgb(255, 0, 81)';
      case 'Learn More':
        return '#27b071';
      default:
        return 'rgb(255 56 0)';
    }
  };

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const toggleDropdown = (index) => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      setSelectedItemIndex(selectedItemIndex === index ? null : index);
      setDropdownVisible(!isDropdownVisible);
    }
  };

  const toggleCommentDropdown = (index) => {
    setSelectedItemCommentIndex(selectedItemCommentIndex === index ? null : index);
    setCommentDropdownVisible(!isCommentDropdownVisible);
  };

  const toggleReplyDropdown = (item, index) => {
    setSelectedItemReplyIndex(selectedItemReplyIndex === item.id ? null : item.id);
    setReplyDropdownVisible(!isReplyDropdownVisible);
  };

  const closeSubMenu = (e) => {
    if (selectedItemIndex !== null && !e.target.closest('.dropdown-content')) {
      setSelectedItemIndex(null);
    }
    if (selectedItemCommentIndex !== null && !e.target.closest('.dropdown-content')) {
      setSelectedItemCommentIndex(null);
    }
    if (selectedItemReplyIndex !== null && !e.target.closest('.dropdown-content')) {
      setSelectedItemReplyIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeSubMenu);
    return () => {
      document.removeEventListener('click', closeSubMenu);
    };
  }, [isDropdownVisible, isCommentDropdownVisible, isReplyDropdownVisible]);

  const handleEdit = (item) => {
    setEditData(item);
    setPostId(item.id);
    setEditShow(true);
    setSelectedItemIndex(null);
  };
  const handleReport = (item) => {
    setReportData(item);
    setReportShow(true);
    setSelectedItemIndex(null);
  };

  const handleShare = (item) => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      setShareData(item);
      setShareShow(true);
      setSelectedItemIndex(null);
    }
  };

  const handleDeleteConfirmed = (id: any) => {
    setSelectedItemIndex(null);
    confirmAlert({
      title: "Delete",
      message: "Are you sure to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteProfessionalPagePost(id),
        },
        {
          label: "No",
          onClick: () => { }
        }
      ]
    });
  };

  const deleteProfessionalPagePost = async (id: any) => {
    try {
      if (id) {
        await professionalPageApiService.deleteProfessionalpagePost(id);
        toast.success('Property Deleted Successfully', { containerId: 'TR' });
        window.location.reload();
        return;
      }
    } catch (error) {
      console.log("ERROR occured deleteProperty() error=== " + JSON.stringify(error));
    }
  };

  const getProfessionalPageDetails = async () => {
    try {
      const userId = StorageService.getUserId();
      console.log(userId);
      if (userId) {
        let res = await professionalPageApiService.getProfessionalPageData(userId);
        setUserPageData(res);
        const activePageItem = res.find(item => item.activePage === true);
        const userPageId = activePageItem?.id;
        getProfessionalPageById(userPageId);
      } else {
        getProfessionalPageById('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProfessionalPageById = async (userPageId) => {
    try {
      let res = await professionalPageApiService.getProfessionalPageById(professionalPageId);
      const addPostOptionExists = res.id === userPageId;
      setHasAddPostOption(addPostOptionExists);
      setData(res);
      setProfessionalPagePosts(res.professionalPagePosts);
    } catch (error) {
      console.log(error);
    }
  };

  const saveProfessionalPagePostComment = async (index, item) => {
    try {
      let payload: PagePostCommentPayload = {
        comments: comment[index],
        postId: item.id,
        pageId: userPageId
      };
      await professionalPageApiService.saveProfessionalPagePostComment(payload);
      handleCommentOnChange(index, '');
      getProfessionalPageById(userPageId);
    } catch (error) {
      console.log(error)
    }
  };

  const saveProfessionalPagePostCommentReply = async (index, item) => {
    try {
      let payload: PagePostCommentReplyPayload = {
        comments: replyComment[index],
        commentId: item.id,
        postId: item.pagePostId,
        pageId: userPageId,
        commentReplyId: 0
      };
      await professionalPageApiService.saveProfessionalPagePostCommentReply(payload);
      handleReplyCommentOnChange(index, '');
      getProfessionalPageById(userPageId);
      getProfessionalPagePostCommentReplyByCommentId(item.id);
    } catch (error) {
      console.log(error)
    }
  };

  const saveProfessionalPagePostChildCommentReply = async (index, item) => {
    try {
      let payload: PagePostCommentReplyPayload = {
        comments: replyChildComment[index],
        commentId: item.commentId,
        postId: item.pagePostId,
        pageId: userPageId,
        commentReplyId: item.id
      };
      await professionalPageApiService.saveProfessionalPagePostCommentReply(payload);
      handleChildReplyCommentOnChange(index, '');
      getProfessionalPagePostCommentReplyByCommentId(item.commentId);
      getProfessionalPagePostCommentReplyByParentId(item, index);
    } catch (error) {
      console.log(error)
    }
  };

  const saveFavouritePost = async (item: any, index: any) => {
    try {
      let payload: FavouritePostPayload = {
        userPageId: userPageId,
        pagePostId: item.id
      };
      await professionalPageApiService.saveFavouritePost(payload);
      getProfessionalPageDetails();
    } catch (error) {
      console.log(error)
    }
  };

  const getProfessionalPagePostCommentByPostId = async (index, postId) => {
    try {
      let res = await professionalPageApiService.getProfessionalPagePostCommentByPostId(postId);
      setShowCommentSection(showCommentSection === index ? null : index);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const saveProfessionalPagePostLike = async (item, index) => {
    try {
      let payload: PagePostLikePayload = {
        postId: item.id,
        likedByPageId: userPageId
      };
      await professionalPageApiService.saveProfessionalPagePostLike(payload);
      getProfessionalPageById(userPageId);
    } catch (error) {
      console.log(error)
    }
  };

  const handleReplyClick = (commentIndex) => {
    setShowReplyInput(commentIndex);
  };

  const handleChildReplyClick = (commentReplyId) => {
    setShowChildReplyInput(prevState => {
      const updatedState = { ...prevState };
      Object.keys(updatedState).forEach(key => {
        updatedState[key] = false;
      });
      updatedState[commentReplyId] = !prevState[commentReplyId];

      return updatedState;
    });
  };

  const handleLikeClick = (item, index) => {
    setLikedPosts((prevLikedPosts) => {
      const newLikedPosts = [...prevLikedPosts];
      newLikedPosts[index] = !newLikedPosts[index];
      return newLikedPosts;
    });
    saveProfessionalPagePostLike(item, index);
  };

  const handleImageClick = (imageData: any) => {
    navigate(`/view-profession-image`, { state: { data, selectedImage: imageData, professionalPagePosts, professionalPageId } });
  };

  const handleDateClick = (pageId: number, postId: number, index: any) => {
    navigate(`/view-professional-page-post?pageId=${pageId}&postId=${postId}`);
    console.log(`View button clicked for pageId: ${pageId}, postId: ${postId}`);
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    const istOffset = 5.5 * 60 * 60 * 1000; // 5 hours and 30 minutes
    const istDate = new Date(date.getTime() + istOffset);
    console.log('istDate:', istDate)
    return istDate.toLocaleString('en-US', {
      timeZone: 'Asia/Kolkata',
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const handleLikeClickIcon = (item, index) => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      handleLikeClick(item, index);
    }
  };

  const handleCommentClickIcon = (index, postId) => {
    let user = StorageService.isLoggedIn();
    if (!user) {
      // showLoggedInConfirmDialog();
      handleShowLogInDialog();
    } else {
      setCommentReply([]);
      setVisibleReplies([]);
      setShowCommentSection(showCommentSection === index ? null : index);
      getProfessionalPagePostCommentByPostId(index, postId);
    }
  };

  const handlePostImageClick = (imageData) => {
    handleImageClick(imageData);
  };

  const handlePostDateClick = (pageId, postId, index) => {
    handleDateClick(pageId, postId, index);
  };

  const showLoggedInConfirmDialog = () => {
    confirmAlert({
      title: 'Alert',
      message: 'You have to logIn.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => { navigate('/login'); }
        },
        {
          label: 'No',
          onClick: () => { console.log('clicked no') }
        }
      ]
    });
  };

  const handleCommentReport = (item) => {
    setReportCommentData(item);
    setReportCommentShow(true);
    setSelectedItemCommentIndex(null);
  }

  const handleCommentReplyReport = (item) => {
    setReportCommentReplyData(item);
    setReportCommentReplyShow(true);
    setSelectedItemReplyIndex(null);
  }

  const handleDeleteCommentDialog = (id: any) => {
    setSelectedItemCommentIndex(null);
    confirmAlert({
      title: "Delete",
      message: "Are you sure to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteComment(id),
        },
        {
          label: "No",
          onClick: () => { }
        }
      ]
    });
  };

  const deleteComment = async (id: any) => {
    try {
      if (id) {
        await professionalPageApiService.deleteComment(id);
        toast.success('Comment has been Deleted Successfully', { containerId: 'TR' });
        getProfessionalPageById('');
        return;
      }
    } catch (error) {
      console.log("ERROR occured deleteProperty() error=== " + JSON.stringify(error));
    }
  };

  const handleDeleteCommentReplyDialog = (item: any) => {
    setSelectedItemReplyIndex(null);
    confirmAlert({
      title: "Delete",
      message: "Are you sure to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCommentReply(item),
        },
        {
          label: "No",
          onClick: () => { }
        }
      ]
    });
  };

  const deleteCommentReply = async (item: any) => {
    try {
      if (item) {
        await professionalPageApiService.deleteCommentReply(item.id);
        toast.success('Comment has been Deleted Successfully', { containerId: 'TR' });
        getProfessionalPageById('');
        getProfessionalPagePostCommentReplyByCommentId(item.commentId);
        return;
      }
    } catch (error) {
      console.log("ERROR occured deleteProperty() error=== " + JSON.stringify(error));
    }
  };

  const handleDeleteCommentChildReplyDialog = (item: any, replyId: any) => {
    setSelectedItemReplyIndex(null);
    confirmAlert({
      title: "Delete",
      message: "Are you sure to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCommentChildReply(item, replyId),
        },
        {
          label: "No",
          onClick: () => { }
        }
      ]
    });
  };

  const deleteCommentChildReply = async (item: any, replyId: any) => {
    try {
      if (item) {
        await professionalPageApiService.deleteCommentReply(item.id);
        toast.success('Comment has been Deleted Successfully', { containerId: 'TR' });
        getProfessionalPageById('');
        getChildCommentReplyByParentId(replyId);
        return;
      }
    } catch (error) {
      console.log("ERROR occured deleteProperty() error=== " + JSON.stringify(error));
    }
  };

  useEffect(() => {
    getProfessionalPageDetails();
  }, [professionalPageId]);

  useEffect(() => {
    if (Array.isArray(professionalPagePosts)) {
      const updatedLikedPosts = professionalPagePosts.map((post) =>
        post.professionalPagePostLikeData.some((like) => like.likedByPageId === userPageId)
      );
      setLikedPosts(updatedLikedPosts);

      const updatedFavPosts = professionalPagePosts.map((post) =>
        post.professionalPageFavouritePostDataList.some((fav) => fav.userPageId === userPageId)
      );
      setIsSaved(updatedFavPosts);
    }
  }, [professionalPagePosts, userPageId]);

  const formatCommentTime = (createdAt) => {
    const currentDate = new Date();
    const commentDate = new Date(createdAt);
    const seconds = Math.floor((currentDate.getTime() - commentDate.getTime()) / 1000);
    if (seconds < 5) {
      return 'just now';
    }
    else if (seconds < 60) {
      return `${seconds}s`;
    }
    else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes}m`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours}h`;
    } else if (seconds < 2592000) {
      const days = Math.floor(seconds / 86400);
      return `${days}d`;
    } else if (seconds < 31536000) {
      const months = Math.floor(seconds / 2592000);
      return `${months}m`;
    } else {
      const years = Math.floor(seconds / 31536000);
      return `${years}y`;
    }
  };

  const handleViewPage = (userId: number, id: number) => {
    navigate(`/view-profession-profile?userId=${userId}&id=${id}`);
    console.log(`View button clicked for userId: ${userId}, id: ${id}`);
  };

  const renderChildReplies = (parentComment, replyId, pageIdOfPost) => {

    if (!Array.isArray(parentComment)) {
      console.error('Parent comment is not an array:', parentComment);
      return null;
    }

    return parentComment.map((childCommentReply, childCommentReplyIndex) => (
      <>
        <Row className="comment-sec mt-0" key={childCommentReply.id}>
          <div className="post-comment-content p-1 pt-0">
            <Col md={1}>
              <img className="post-comment-profile-image"
                src={childCommentReply.pageProfileImageUrl != null ? childCommentReply.pageProfileImageUrl : Avatar}
                alt="Profile" />
            </Col>
            <Col md={11}>
              <div className="d-flex align-items-center">
                <Col md={11} className="post-comment-text-container p-2 pt-0 pb-1">
                  <span className="post-comment-username page-name-style" style={{ wordBreak: "break-word" }}
                    onClick={() => handleViewPage(childCommentReply.createdBy, childCommentReply.pageId)}>
                    {childCommentReply.pageName}
                  </span>
                  <div className="post-comment-text">{childCommentReply.comment}</div>
                </Col>
                <Col md={1} onClick={(e) => e.stopPropagation()}>
                  <MoreHorizIcon className="m-2 more-horizicon"
                    onClick={() => toggleReplyDropdown(childCommentReply, childCommentReplyIndex)} />
                  {userPageId == pageIdOfPost && userPageId != childCommentReply.pageId && pageIdOfPost != childCommentReply.pageId && (
                    <div className={`dropdown-content ${selectedItemReplyIndex === childCommentReply.id ? 'show' : ''}`}
                      style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                      <div className="ms-1" onClick={() => handleDeleteCommentChildReplyDialog(childCommentReply, replyId)}>
                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                        Delete
                      </div>
                      <div className="ms-1" onClick={() => handleCommentReplyReport(childCommentReply)}>
                        <FontAwesomeIcon icon={faFlag} className="me-2" />
                        Report
                      </div>
                    </div>
                  )}
                  {((userPageId == pageIdOfPost) || (userPageId != pageIdOfPost)) && userPageId == childCommentReply.pageId && ((pageIdOfPost == childCommentReply.pageId) || (pageIdOfPost != childCommentReply.pageId)) && (
                    <div className={`dropdown-content ${selectedItemReplyIndex === childCommentReply.id ? 'show' : ''}`}
                      style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                      <div className="ms-1" onClick={() => handleDeleteCommentChildReplyDialog(childCommentReply, replyId)}>
                        <FontAwesomeIcon icon={faTrash} className="me-2" />
                        Delete
                      </div>
                    </div>
                  )}
                  {userPageId != pageIdOfPost && userPageId != childCommentReply.pageId && (
                    <div className={`dropdown-content ${selectedItemReplyIndex === childCommentReply.id ? 'show' : ''}`}
                      style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                      <div className="ms-1" onClick={() => handleCommentReplyReport(childCommentReply)}>
                        <FontAwesomeIcon icon={faFlag} className="me-2" />
                        Report
                      </div>
                    </div>
                  )}
                </Col>
              </div>
              {childCommentReply.professionalPageCommentReplyLikeDataList && childCommentReply.professionalPageCommentReplyLikeDataList.length > 0 && (
                <div className="d-flex" style={{ marginTop: '-7px' }}>
                  <div className="recommend-container d-flex align-items-center">
                    <RecommendIcon style={{ color: '#007bff', fontSize: 'large' }} />
                    <span style={{ color: 'gray' }}>
                      {childCommentReply.professionalPageCommentReplyLikeDataList.length}
                    </span>
                  </div>
                </div>
              )}
              <div className="reply-container">
                <a className="">
                  {formatCommentTime(childCommentReply.createdAt)}
                </a>
                <a className="ms-2"
                  style={{
                    color: childCommentReply.professionalPageCommentReplyLikeDataList.some(likeData => likeData.userPageId === userPageId) ? '#007bff' : 'gray'
                  }}
                  onClick={() => handleLikeReplyChildCommentClick(childCommentReply, childCommentReplyIndex, replyId)}>
                  {childCommentReply.professionalPageCommentReplyLikeDataList.some(likeData => likeData.userPageId === userPageId) ? 'Liked' : 'Like'}
                </a>
                <a className="ms-2" onClick={() => handleChildReplyClick(childCommentReply.id)}>
                  Reply
                </a>
              </div>
              {childCommentReply.professionalPageCommentChildReplyData && childCommentReply.professionalPageCommentChildReplyData.length > 0 && (
                <div className="d-flex align-items-end pb-1"
                  style={{
                    cursor: 'pointer',
                    fontWeight: '600',
                    fontSize: 'small',
                    color: 'gray'
                  }}>
                  <SubdirectoryArrowRightIcon /> Replies
                </div>
              )}
            </Col>
          </div>
          {showChildReplyInput[childCommentReply.id] && (
            <Col className="comment-sec mt-0 ps-5">
              <img
                src={activePageItem?.profileImageUrl != null ? activePageItem?.profileImageUrl : Avatar}
                alt="Profile" />
              <div className="input-div">
                <textarea
                  value={replyChildComment[childCommentReplyIndex]}
                  placeholder="Write a comment..."
                  onChange={(e) => handleChildReplyCommentOnChange(childCommentReplyIndex, e.target.value)}
                />
                <SendIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => saveChildCommentReply(childCommentReplyIndex, childCommentReply, replyId)}
                />
              </div>
            </Col>
          )}
        </Row>
        {renderChildReplies(childCommentReply.professionalPageCommentChildReplyData, replyId, pageIdOfPost)}
      </>
    ));
  };

  return <>
    <div className="col-sm-12 total-color ">
      {professionalPagePosts.length < 1 ? (
        <div className="p-5 my-4">
          <div className="d-flex justify-content-center">
            <CameraAltOutlinedIcon className="p-3"
              style={{ fontSize: '100px', border: 'solid', borderRadius: '50%' }} />
          </div>
          <div className="text-center"><span className="fs-5">No posts yet</span></div>
        </div>
      ) : (
        <>
          {Array.isArray(professionalPagePosts) && professionalPagePosts.map((item: any, index: any) =>
            <div>
              {item.postType === "NORMAL_POST" && (
                <div className="row card card-post mt-2 mb-2" >
                  <div className="p-0">
                    <div className="d-flex justify-content-between mt-2 col-sm-12 px-3">
                      <div className="d-flex col-sm-11 align-items-center">
                        <img
                          className="card_avatar_img"
                          src={item.pageProfileImageUrl != null ? item.pageProfileImageUrl : Avatar}
                          alt="Profile"
                        />
                        <div className="ms-2 col-sm-11">
                          <div className="fw-semibold align-self-start mt-1"
                            style={{ wordBreak: "break-word" }}>
                            {item.professionalName}
                          </div>
                          <div className="mb-2">
                            <div className="upload_date align-self-start row col-sm-12">
                              <div className="pe-0">
                                <a
                                  data-tooltip-id="my-tooltip-styles"
                                  data-tooltip-content={formattedDate(item.createdAt)}
                                >
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handlePostDateClick(item.professionalPageId, item.id, index)}
                                  >
                                    {new Date(item.createdAt).getDate()}{' '}
                                    {new Date(item.createdAt).toLocaleString('default', { month: 'long' })}{' '}
                                    {new Date(item.createdAt).getFullYear()}
                                  </span>
                                </a>
                                <Tooltip id="my-tooltip-styles" className="tooltip" opacity={0.8} place={'bottom'} />
                                <span className="ms-1">·</span>
                                <span className="location_name_size">
                                  <LocationOnIcon className="location_icon_size" />{item.taggedLocationName}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center col-sm-1" onClick={(e) => e.stopPropagation()}>
                        <span className="d-flex align-items-center"
                          onClick={() => toggleDropdown(index)}>
                          <MoreHorizIcon />
                        </span>
                        {hasAddPostOption && (
                          <div
                            className={`dropdown-content ${selectedItemIndex === index ? 'show' : ''}`}>
                            <div className="mb-2 ms-1" onClick={() => handleEdit(item)}>
                              <FontAwesomeIcon icon={faEdit} className="me-2" />
                              Edit post
                            </div>
                            <div className="mb-2" onClick={() => handleIsSavedClick(item, index)}>
                              {isSaved[index] ? (
                                <><TurnedInIcon className="me-1" />
                                  Saved Post</>
                              ) : (
                                <><TurnedInNotIcon className="me-1" />
                                  Save Post</>
                              )}
                            </div>
                            <div className="ms-1" onClick={() => handleDeleteConfirmed(item.id)}>
                              <FontAwesomeIcon icon={faTrash} className="me-2" />
                              Move to trash
                            </div>
                          </div>
                        )}
                        {!hasAddPostOption && (
                          <div
                            className={`dropdown-content ${selectedItemIndex === index ? 'show' : ''}`}
                            style={{ marginRight: '130px' }}>
                            <div className="mb-2" onClick={() => handleIsSavedClick(item, index)}>
                              {isSaved[index] ? (
                                <><TurnedInIcon className="me-1" />
                                  Saved Post</>
                              ) : (
                                <><TurnedInNotIcon className="me-1" />
                                  Save Post</>
                              )}
                            </div>
                            <div className="ms-1" onClick={() => handleReport(item)}>
                              <FontAwesomeIcon icon={faFlag} className="me-2" />
                              Report
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* {item.caption == '' || item.caption == "null" ? (
                      null
                    ) : (
                      <div className="ms-3 me-5">{item.caption}</div>
                    )} */}
                    {
                      item.caption && item.caption !== "null" ? (
                        <div className="ms-3 me-5">
                          <div className={isExpanded[index] ? 'full-caption' : 'caption'}>
                            {item.caption}
                          </div>
                          {item.caption.split(' ').length > 20 && (
                            <span className="read-more-caption" onClick={() => handleReadMore(index)}>
                              {isExpanded[index] ? 'Read less...' : 'Read more...'}
                            </span>
                          )}
                        </div>
                      ) : null
                    }
                    {item.professionalPagePostImageGalleryData && (
                      <Carousel interval={null}
                        indicators={item.professionalPagePostImageGalleryData.length > 1}
                        controls={item.professionalPagePostImageGalleryData.length > 1}>
                        {item.professionalPagePostImageGalleryData.map((imageData, imageIndex) => (
                          <Carousel.Item key={imageIndex}>
                            <div className="single-post-image-overlay d-flex justify-content-center align-items-center"
                              style={{ cursor: 'pointer' }}
                              onClick={() => handlePostImageClick(imageData)}>
                              {item.professionalPagePostImageGalleryData.length > 1 &&
                                <div className="d-flex align-items-center justify-content-center video-play-icon m-3 p-1 px-2"
                                  style={{ borderRadius: '15px', position: 'absolute', top: '0', right: '0' }}>
                                  <span style={{ fontSize: 'small' }}>
                                    {imageIndex + 1}/{item.professionalPagePostImageGalleryData.length}
                                  </span>
                                </div>
                              }
                              {imageData.mimeType === 'IMAGE' ? (
                                <img
                                  src={imageData.imagePath}
                                  width={'100%'}
                                  height={'100%'}
                                  style={{ maxHeight: '65vh', objectFit: 'cover' }}
                                  alt={`Image ${imageIndex + 1}`}
                                />
                                // <div className="image-container">
                                //   <img
                                //     src={imageData.imagePath}
                                //     alt="Background Image"
                                //     className="background-image"
                                //   />
                                //   <img
                                //     src={imageData.imagePath}
                                //     width={'100%'}
                                //     height={'100%'}
                                //     style={{ maxHeight: '65vh' }}
                                //     alt={`Image ${imageIndex + 1}`}
                                //     className="foreground-image"
                                //   />
                                // </div>
                              ) : (
                                <>
                                  <video
                                    className=""
                                    height={'100%'}
                                    width={'100%'}
                                    style={{ maxHeight: '65vh' }}
                                    controls={playingVideoIndex === imageData.id}
                                    onPlay={() => handlePlay(imageData.id)}
                                    onPause={() => handlePause(imageData.id)}
                                    ref={(ref) => (videoRefs.current[imageData.id] = ref)}
                                  >
                                    <source src={imageData.imagePath} />
                                  </video>
                                  {/* <div dangerouslySetInnerHTML={{ __html: '<iframe width="560" height="315" src="https://www.youtube.com/embed/5QlTZEogGrE?si=VG1W2VIIsCMhDM2h" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>' }}></div> */}
                                  {playingVideoIndex !== imageData.id && (
                                    <div className="d-flex align-items-center justify-content-center video-play-icon"
                                      onClick={(e) => handlePlayIconClick(e, imageData.id)}>
                                      <PlayCircleOutlineIcon style={{ fontSize: 'xxx-large' }} />
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    )}
                    {item.callToAction == 0 || item.callToAction == null ? (
                      null
                    ) : (
                      <>
                        {item.callToActionUrl && item.callToActionUrl != 'null' && (
                          <a href={item.callToActionUrl.startsWith('http') ? item.callToActionUrl : `http://${item.callToActionUrl}`} className="shop-now-link" target="_blank" rel="nofollow"
                            style={{ textDecoration: 'none' }}>
                            <div className="shop-now-bar mb-2" style={{ backgroundColor: getRandomColor() }}>
                              {item.callToActionLabel}
                              <span>
                                <ChevronRightIcon />
                              </span>
                            </div>
                          </a>
                        )}
                      </>
                    )}

                    {item.professionalPagePostLikeData.length > 0 && (
                      <div className="px-2 mb-2 d-flex align-items-center">
                        <RecommendIcon style={{ color: '#007bff' }} />
                        {item.professionalPagePostLikeData.length}
                      </div>
                    )}
                    <hr />
                  </div>
                  <div className="p-0">
                    <div className="d-flex col-sm-12 justify-content-evenly">
                      <div className="col-sm-4 d-flex justify-content-center">
                        <span className="d-flex align-items-center"
                          style={{ cursor: 'pointer', color: likedPosts[index] ? '#007bff' : 'black' }}
                          onClick={() => handleLikeClickIcon(item, index)}
                        >
                          <ThumbUpOffAltIcon />
                          {likedPosts[index] ? 'Liked' : 'Like'}
                        </span>
                      </div>
                      <div className="col-sm-4 d-flex justify-content-center">
                        <span className="d-flex align-items-center"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleCommentClickIcon(index, item.id)
                          }}>
                          <ChatBubbleOutlineOutlinedIcon /> Comment
                        </span>
                      </div>
                      <div className="col-sm-4 d-flex justify-content-center">
                        <span className="d-flex align-items-center"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleShare(item)}>
                          <ShareIcon /> Share
                        </span>
                      </div>
                    </div>
                    <hr />
                  </div>
                  {showCommentSection === index && (
                    <>
                      <Col className="comment-sec mt-0">
                        <img
                          // src={activePageItem?.profileImageUrl}
                          src={activePageItem?.profileImageUrl != null ? activePageItem?.profileImageUrl : Avatar}
                          alt="Profile" />
                        <div className="input-div">
                          <textarea
                            value={comment[index]}
                            placeholder="Write a comment..."
                            onChange={e => { handleCommentOnChange(index, e.target.value) }} />
                          <SendIcon style={{ cursor: 'pointer' }}
                            onClick={() => saveProfessionalPagePostComment(index, item)} />
                        </div>
                      </Col>
                      {Array.isArray(item.professionalPageCommentData) &&
                        item.professionalPageCommentData.map((comment, commentIndex) => (
                          <Col key={commentIndex}>
                            {/* Step - 1  */}
                            <div className="post-comment-content">
                              <Col md={1}>
                                <img className="post-comment-profile-image"
                                  // src={comment.pageProfileImageUrl}
                                  src={comment.pageProfileImageUrl != null ? comment.pageProfileImageUrl : Avatar}
                                  alt="" />
                              </Col>
                              <Col md={11}>
                                <div className="d-flex align-items-center">
                                  <Col md={11} className="post-comment-text-container p-2 pt-0">
                                    <span className="post-comment-username page-name-style" style={{ wordBreak: "break-word" }}
                                      onClick={() => handleViewPage(comment.createdBy, comment.pageId)}>
                                      {comment.pageName}
                                    </span>
                                    <div className="post-comment-comment-text">
                                      {comment.comment}
                                    </div>
                                  </Col>
                                  <Col md={1} onClick={(e) => e.stopPropagation()}>
                                    <MoreHorizIcon className="m-2 more-horizicon"
                                      onClick={() => toggleCommentDropdown(commentIndex)} />
                                    {userPageId == item.professionalPageId && userPageId != comment.pageId && item.professionalPageId != comment.pageId && (
                                      <div className={`dropdown-content ${selectedItemCommentIndex === commentIndex ? 'show' : ''}`}
                                        style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                        <div className="ms-1" onClick={() => handleDeleteCommentDialog(comment.id)}>
                                          <FontAwesomeIcon icon={faTrash} className="me-2" />
                                          Delete
                                        </div>
                                        <div className="ms-1" onClick={() => handleCommentReport(comment)}>
                                          <FontAwesomeIcon icon={faFlag} className="me-2" />
                                          Report
                                        </div>
                                      </div>
                                    )}
                                    {((userPageId == item.professionalPageId) || (userPageId != item.professionalPageId)) && userPageId == comment.pageId && ((item.professionalPageId == comment.pageId) || (item.professionalPageId != comment.pageId)) && (
                                      <div className={`dropdown-content ${selectedItemCommentIndex === commentIndex ? 'show' : ''}`}
                                        style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                        <div className="ms-1" onClick={() => handleDeleteCommentDialog(comment.id)}>
                                          <FontAwesomeIcon icon={faTrash} className="me-2" />
                                          Delete
                                        </div>
                                      </div>
                                    )}
                                    {userPageId != item.professionalPageId && userPageId != comment.pageId && (
                                      <div className={`dropdown-content ${selectedItemCommentIndex === commentIndex ? 'show' : ''}`}
                                        style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                        <div className="ms-1" onClick={() => handleCommentReport(comment)}>
                                          <FontAwesomeIcon icon={faFlag} className="me-2" />
                                          Report
                                        </div>
                                      </div>
                                    )}
                                  </Col>
                                </div>
                                {comment.professionalPageCommentLikeDataList && comment.professionalPageCommentLikeDataList.length > 0 && (
                                  <div className="d-flex"
                                    style={{ marginTop: '-10px' }}>
                                    <div className="recommend-container d-flex align-items-center">
                                      <RecommendIcon style={{
                                        color: '#007bff',
                                        fontSize: 'large'
                                      }} />
                                      <span style={{ color: 'gray' }}>{comment.professionalPageCommentLikeDataList.length}</span>
                                    </div>
                                  </div>
                                )}
                                <div className="reply-container">
                                  <a className="">
                                    {formatCommentTime(comment.createdAt)}
                                  </a>
                                  <a className="ms-2"
                                    style={{
                                      color: comment.professionalPageCommentLikeDataList.some(likeData => likeData.userPageId === userPageId) ? '#007bff' : 'gray'
                                    }}
                                    onClick={() => handleLikeCommentClick(comment, commentIndex)}
                                  >
                                    {comment.professionalPageCommentLikeDataList.some(likeData => likeData.userPageId === userPageId) ? 'Liked' : 'Like'}
                                  </a>
                                  <a className="ms-2"
                                    onClick={() => handleReplyClick(commentIndex)}>
                                    Reply
                                  </a>
                                </div>
                                {comment.professionalPageCommentReplyData && comment.professionalPageCommentReplyData.length > 0 && (
                                  <div className="d-flex align-items-end"
                                    onClick={() => handleViewRepliesClick(comment.id, commentIndex, item)}
                                    style={{
                                      cursor: 'pointer',
                                      fontWeight: '600',
                                      fontSize: 'small',
                                      color: 'gray'
                                    }}>
                                    <SubdirectoryArrowRightIcon />
                                    {visibleReplies[commentIndex] ? 'Replies' : 'View Replies'}
                                  </div>
                                )}
                              </Col>
                            </div>
                            {showReplyInput === commentIndex && (
                              <Col className="comment-sec mt-0 ps-5">
                                <img
                                  // src={activePageItem?.profileImageUrl}
                                  src={activePageItem?.profileImageUrl != null ? activePageItem?.profileImageUrl : Avatar}
                                  alt="Profile" />
                                <div className="input-div">
                                  <textarea
                                    value={replyComment[commentIndex]}
                                    placeholder="Write a comment..."
                                    onChange={e => { handleReplyCommentOnChange(commentIndex, e.target.value) }}
                                  />
                                  <SendIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => saveProfessionalPagePostCommentReply(commentIndex, comment)}
                                  />
                                </div>
                              </Col>
                            )}
                            {/* Step - 2  */}
                            {visibleReplies[commentIndex] && commentReply?.map((commentReply, commentReplyIndex) => (
                              <Row className="comment-sec mt-0 ps-5" key={commentReplyIndex}>
                                <div className="post-comment-content pt-0 p-2">
                                  <Col md={1}>
                                    <img className="post-comment-profile-image"
                                      style={{ height: '38px', width: '38px' }}
                                      // src={commentReply.pageProfileImageUrl}
                                      src={commentReply.pageProfileImageUrl != null ? commentReply.pageProfileImageUrl : Avatar}
                                      alt="Profile" />
                                  </Col>
                                  <Col md={11}>
                                    <div className="d-flex align-items-center">
                                      <Col md={11} className="post-comment-text-container p-2 pt-0 pb-1">
                                        <span className="post-comment-username page-name-style" style={{ wordBreak: "break-word" }}
                                          onClick={() => handleViewPage(commentReply.createdBy, commentReply.pageId)}>
                                          {commentReply.pageName}
                                        </span>
                                        <div className="post-comment-text">{commentReply.comment}</div>
                                      </Col>
                                      <Col md={1} onClick={(e) => e.stopPropagation()}>
                                        <MoreHorizIcon className="m-2 more-horizicon"
                                          onClick={() => toggleReplyDropdown(commentReply, commentReplyIndex)} />
                                        {userPageId == item.professionalPageId && userPageId != commentReply.pageId && item.professionalPageId != commentReply.pageId && (
                                          <div className={`dropdown-content ${selectedItemReplyIndex === commentReply.id ? 'show' : ''}`}
                                            style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                            <div className="ms-1" onClick={() => handleDeleteCommentReplyDialog(commentReply)}>
                                              <FontAwesomeIcon icon={faTrash} className="me-2" />
                                              Delete
                                            </div>
                                            <div className="ms-1" onClick={() => handleCommentReplyReport(commentReply)}>
                                              <FontAwesomeIcon icon={faFlag} className="me-2" />
                                              Report
                                            </div>
                                          </div>
                                        )}
                                        {((userPageId == item.professionalPageId) || (userPageId != item.professionalPageId)) && userPageId == commentReply.pageId && ((item.professionalPageId == commentReply.pageId) || (item.professionalPageId != commentReply.pageId)) && (
                                          <div className={`dropdown-content ${selectedItemReplyIndex === commentReply.id ? 'show' : ''}`}
                                            style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                            <div className="ms-1" onClick={() => handleDeleteCommentReplyDialog(commentReply)}>
                                              <FontAwesomeIcon icon={faTrash} className="me-2" />
                                              Delete
                                            </div>
                                          </div>
                                        )}
                                        {userPageId != item.professionalPageId && userPageId != commentReply.pageId && (
                                          <div className={`dropdown-content ${selectedItemReplyIndex === commentReply.id ? 'show' : ''}`}
                                            style={{ marginTop: '-12px', marginLeft: '-75px' }}>
                                            <div className="ms-1" onClick={() => handleCommentReplyReport(commentReply)}>
                                              <FontAwesomeIcon icon={faFlag} className="me-2" />
                                              Report
                                            </div>
                                          </div>
                                        )}
                                      </Col>
                                    </div>
                                    {commentReply.professionalPageCommentReplyLikeDataList && commentReply.professionalPageCommentReplyLikeDataList.length > 0 && (
                                      <div className="d-flex"
                                        style={{ marginTop: '-7px' }}>
                                        <div className="recommend-container d-flex align-items-center">
                                          <RecommendIcon style={{
                                            color: '#007bff',
                                            fontSize: 'large'
                                          }} />
                                          <span style={{ color: 'gray' }}>
                                            {commentReply.professionalPageCommentReplyLikeDataList.length}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    <div className="reply-container">
                                      <a className="">
                                        {formatCommentTime(commentReply.createdAt)}
                                      </a>
                                      <a className="ms-2"
                                        style={{
                                          color: commentReply.professionalPageCommentReplyLikeDataList.some(likeData => likeData.userPageId === userPageId) ? '#007bff' : 'gray'
                                        }}
                                        onClick={() => handleLikeReplyCommentClick(commentReply, commentReplyIndex)}
                                      >
                                        {commentReply.professionalPageCommentReplyLikeDataList.some(likeData => likeData.userPageId === userPageId) ? 'Liked' : 'Like'}
                                      </a>
                                      <a className="ms-2"
                                        onClick={() => handleChildReplyClick(commentReply.id)}>
                                        Reply
                                      </a>
                                    </div>
                                    {commentReply.professionalPageCommentChildReplyData && commentReply.professionalPageCommentChildReplyData.length > 0 && (
                                      <div className="d-flex align-items-end"
                                        onClick={() => handleViewChildRepliesClick(commentReply, commentReplyIndex, commentReply.professionalPageCommentChildReplyData)}
                                        style={{
                                          cursor: 'pointer',
                                          fontWeight: '600',
                                          fontSize: 'small',
                                          color: 'gray'
                                        }}>
                                        <SubdirectoryArrowRightIcon />
                                        {visibleChildReplies[commentReply.id] ? 'Replies' : 'View Replies'}
                                      </div>
                                    )}
                                  </Col>
                                </div>
                                {showChildReplyInput[commentReply.id] && (
                                  <Col className="comment-sec mt-0 ps-5">
                                    <img
                                      // src={activePageItem?.profileImageUrl}
                                      src={activePageItem?.profileImageUrl != null ? activePageItem?.profileImageUrl : Avatar}
                                      alt="" />
                                    <div className="input-div">
                                      <textarea
                                        value={replyChildComment[commentReplyIndex]}
                                        placeholder="Write a comment..."
                                        onChange={e => { handleChildReplyCommentOnChange(commentReplyIndex, e.target.value) }}
                                      />
                                      <SendIcon
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => saveProfessionalPagePostChildCommentReply(commentReplyIndex, commentReply)}
                                      />
                                    </div>
                                  </Col>
                                )}
                                {/* Step - 3  */}
                                {visibleChildReplies[commentReply.id] && renderChildReplies(commentChildReply, commentReply.id, item.professionalPageId)}
                              </Row>
                            ))}
                          </Col>
                        ))}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
      {hasAddPostOption && (
        <button className="btn btn-primary custom-btn mb-3" onClick={handleShow}>
          <AddPhotoAlternateIcon />
        </button>
      )}
      <Modal size="lg" show={show} onHide={handleClose}>
        <AddProfessionPost data={data} handleClose={handleClose} />
      </Modal>
      <Modal size="lg" show={editShow} onHide={editHandleClose}>
        <EditProfessionPost data={editData} postId={postId} handleClose={editHandleClose} />
      </Modal>
      <Modal size="lg" show={reportShow} onHide={reportClose}>
        <ProfessionPostReport data={reportData} reportType={'POST_REPORT'} handleClose={reportClose} />
      </Modal>
      <Modal size="lg" show={reportCommentShow} onHide={reportCommentHandleClose}>
        <ProfessionPostReport data={reportCommentData} reportType={'COMMENT_REPORT'} handleClose={reportCommentHandleClose} />
      </Modal>
      <Modal size="lg" show={reportCommentReplyShow} onHide={reportCommentReplyHandleClose}>
        <ProfessionPostReport data={reportCommentReplyData} reportType={'REPLY_REPORT'} handleClose={reportCommentReplyHandleClose} />
      </Modal>
      <Modal size="lg" show={shareShow} onHide={shareClose}>
        <ProfessionPostShare data={shareData} handleClose={shareClose} />
      </Modal>
      <Modal show={showLogInDialog} onHide={handleCloseLogInDialog} backdrop="static"
        centered className="login-page-dialog-modal">
        <LoginPageDialog handleClose={handleCloseLogInDialog} />
      </Modal>
    </div >
  </>
}
export default ViewProfessionPost;