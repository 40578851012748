import { useState, useEffect } from "react";
import { Button, Col, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthApiService from "../../data/api/services/auth/auth-api-service";
import { AuthPayload } from "../../data/api/services/auth/auth-payload";
import BenefitApiService from "../../data/api/services/benefit-api/benefit-api-service";
import RegisterApiService from "../../data/api/services/register/register-api-service";
import SabPaisaApiService from "../../data/api/services/sabpaisa/sabpaisa-api-service";
import { Header } from "../../layouts";
import FreeQuestions from "../free-questions/free-questions";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StorageService from "../../data/storage/storage-service";
import { SignupPayload, UpdatePayload, UpdateRegistrationPayload } from "../../data/api/services/register/signup-payload";
import ApiConfig from "../../data/api/api-config";
import "../../pages/veedu-benefits/pricing.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const Pricing = () => {

    let navigate: any = useNavigate();
    const [selectedColumn, setSelectedColumn] = useState(null);
    const benefitApiService = new BenefitApiService();
    const [benifitData, setBenifitData] = useState<any>([]);
    const [priceType, setPriceType] = useState('');
    const [membershipPlanData, setMembershipPlanData] = useState<any>([]);
    const [membershipTypes, setMembershipTypes] = useState<any>([]);
    const [isFirstDataSelected, setIsFirstDataSelected] = useState(false);
    const [userType, setUserType] = useState('');
    const [selectedMembershipPlan, setSelectedMembershipPlan] = useState<any>([]);
    const [encData, setEncData] = useState('');
    const [clientCode, setClientCode] = useState('');
    const registerService: RegisterApiService = new RegisterApiService();
    const authService: AuthApiService = new AuthApiService();
    const sabPaisaApiService = new SabPaisaApiService();
    const apiConfig = new ApiConfig();
    const [sabpaisaUrl, setSabpaisaUrl] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [mobileValidationError, setMobileNoValidationError] = useState(false);
    const [emailValidationError, setEmailValidationError] = useState(false);
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [showMobileAndEmail, setShowMobileAndEmail] = useState(false);
    const handleCloseMobileAndEmail = () => setShowMobileAndEmail(false);
    const handleShowMobileAndEmail = () => setShowMobileAndEmail(true);
    const [userData, setUserData] = useState<any>([]);
    const [isSubscribed, setIsSubscribed] = useState(false);

    const handleRadioChange = (event: any) => {
        console.log("SDFGHJK", event);
        const newValue = event.target.value;
        setPriceType(newValue);
    };

    const getTemplateData = async () => {
        try {
            let data = await benefitApiService.getTemplateData();
            setMembershipTypes(data.membershipTypeData);
            setBenifitData(data.memberShipBenefitData);
        } catch (error) {
            console.log(error);
        }
    }

    const getLogin = async () => {
        navigate('/');
    }

    const handleColumnSelection = (column: any) => {
        setIsFirstDataSelected(column.orderId === membershipPlanData[0].orderId);
        setSelectedColumn(column.orderId);
        if (column.orderId === 1) {
            setUserType("ROLE_VIEW_USER");
        }
        if (column.orderId === 2) {
            setUserType("ROLE_PRO_USER");
            setSelectedMembershipPlan(membershipPlanData[1]);
        }
        if (column.orderId === 3) {
            setUserType("ROLE_PRO_USER");
            setSelectedMembershipPlan(membershipPlanData[2]);
        }
        setSelectedMembershipPlan(column);
    };

    const executeRegisterApi = async () => {
        try {
            let url = userData.id;
            let payload: UpdateRegistrationPayload = {
                id: userData.id,
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: email,
                mobile: mobile,
                password: userData.password,
                userType: userType,
                locationId: userData.locationId,
                membershipBenefitMapperId: selectedMembershipPlan.id,
                amount: selectedMembershipPlan.amount,
                promoCode: userData.promoCode,
                logInType: userData.logInType,
                professionTypeId: ""
            };
            if (payload.userType == 'ROLE_PRO_USER') {
                if (!payload.mobile || !payload.email) {
                    handleShowMobileAndEmail();
                } else {
                    getSabPaisaResponse(payload);
                }
            } else {
                navigate('/');
            }
        } catch (error) {
            console.log(error)
        }
    }

    const [shouldSubmit, setShouldSubmit] = useState(false);

    const getSabPaisaResponse = async (registrationPayload) => {
        try {
            let url = `?payerName=${registrationPayload.firstName}&payerEmail=${registrationPayload.email}
            &payerMobile=${registrationPayload.mobile}&amount=${registrationPayload.amount}
            &pageType=${'PRICING_PAGE'}&logInType=${registrationPayload.logInType}`;
            let data = await sabPaisaApiService.getSabPaisaResponse(url);
            console.log(data);
            setEncData(data.encData);
            setClientCode(data.clientCode);
            setShouldSubmit(true);
        } catch (error) {
            if (error.response && error.response.data) {
                console.error('Error response data:', error.response.data);
            } else {
                console.error('Error fetching SabPaisa response:', error.message);
            }
        }
    };

    const handleSubmit = () => {
        const form = document.getElementById('sabPaisaForm') as HTMLFormElement;
        if (form) {
            form.submit();
        }
    };

    useEffect(() => {
        if (shouldSubmit) {
            handleSubmit();
        }
    }, [encData, clientCode]);

    const getUser = async () => {
        try {
            const userId = StorageService.getUserId();
            let res = await authService.getUser(userId);
            console.log('dataaaaaa=>', res);
            setUserData(res);
            setEmail(res.email);
            setMobile(res.mobile);
            getSubscriptionByPersonId(userId);
            let plan = await benefitApiService.getMemberShipPlans()
            setMembershipPlanData(plan);
            if (res.membershipBebefitMapperId === 1) {
                setSelectedColumn(1);
                setSelectedMembershipPlan(plan[0]);
                setIsFirstDataSelected(true);
                setUserType("ROLE_VIEW_USER");
            }
            if (res.membershipBebefitMapperId === 2) {
                setSelectedColumn(2);
                setSelectedMembershipPlan(plan[1]);
                setUserType("ROLE_PRO_USER");
            }
            if (res.membershipBebefitMapperId === 3) {
                setSelectedColumn(3);
                setSelectedMembershipPlan(plan[2]);
                setUserType("ROLE_PRO_USER");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getSubscriptionByPersonId = async (personId) => {
        try {
            let res = await benefitApiService.getSubscriptionByPersonId(personId);
            console.log(res);
            if (res) {
                setIsSubscribed(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getUser();
        getTemplateData();
        setSabpaisaUrl(apiConfig.getSabpaisaURL);
    }, []);

    const handleEmailChange = (value: any) => {
        setEmail(value);
        setEmailValidationError(false);
    };

    const handleMobileNumberChange = (value: any) => {
        const formattedValue = value.replace(/\D/g, '').slice(0, 10);
        setMobile(formattedValue);
        setMobileNoValidationError(false);
    };

    const validateMobile = () => {
        let isFormValid = true;
        if (!email) {
            setEmailValidationError(true);
            isFormValid = false;
        }
        if (!mobile) {
            setMobileNoValidationError(true);
            isFormValid = false;
        }
        if (mobile) {
            if (mobile.length <= 9) {
                toast.error("Enter a Valid Mobile No. ", { containerId: 'TR' });
                isFormValid = false;
            }
        }
        return isFormValid;
    }

    const handleModalSubmit = () => {
        if (validateMobile()) {
            checkUser();
        }
    };

    const checkUser = async () => {
        try {
            let url = `?mobileNo=${mobile}&email=${email}&logInType=${userData.logInType}&userId=${userData.id}`;
            let data = await authService.fetchUserByMobileNoEmailLogInTypeAndUserType(url);
            console.log('dataaaaaa=>', data);
            if (!data) {
                userData.email = email;
                userData.mobile = mobile;
                updateMobileAndEmail();
            } else {
                toast.error("User Already Exist ", { containerId: 'TR' });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateMobileAndEmail = async () => {
        try {
            let url = userData.id;
            let payload: UpdatePayload = {
                firstName: userData.firstName,
                lastName: userData.lastName,
                email: userData.email,
                mobile: userData.mobile,
                password: userData.password,
                userType: userData.roleCode,
                locationId: userData.locationId,
                membershipBenefitMapperId: userData.membershipBebefitMapperId || "",
                amount: "",
                promoCode: ""
            };
            console.log("kjhkjhkjhkjhkkjn", payload)
            await authService.updatePassword(url, payload);
            setShowMobileAndEmail(false);
            executeRegisterApi();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="mb-5">
                <Header />
            </div>
            <section className="bg-light section">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <div className="sec-heading">
                            <h2 className="fw-bold">Pick Your Perfect plan</h2>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container-fluid bg-light table-container">
                <div className="row">
                    <div className="col-sm-6 mx-auto">
                        <div className="table-responsive d-flex">
                            <table className="table">
                                <thead className="fs-5">
                                    <tr>
                                        <th className="aaa top-header "> </th>
                                    </tr>
                                </thead>
                                <tbody className="fs-5">
                                    {benifitData.map((item, idx) => (
                                        <tr key={idx}>
                                            <td className="p-0 benefit-name">{item.name}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="d-flex">
                                {membershipPlanData.map((item, idx) => (
                                    <table className="table" key={idx}>
                                        <thead className="fs-5">
                                            <tr>
                                                <th
                                                    className={`aaa top-header ${selectedColumn === item.orderId ? 'selected' : ''}`}
                                                    onClick={() => handleColumnSelection(item)}
                                                >
                                                    {item.memberShipTypeLabel}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="fs-5">
                                            {item.memberShipBenefitDataList.map((item1, idx) => (
                                                <tr key={idx}>
                                                    <td className={`aaa  ${selectedColumn === item.orderId ? 'selected' : ''}`}
                                                        onClick={() => handleColumnSelection(item)}>
                                                        {item1.hasBenefit ? <b><DoneOutlineIcon className="tik-icon" /></b> : <CloseIcon className="text-danger" />}
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td className={`aaa  ${selectedColumn === item.orderId ? 'selected' : ''}`}
                                                    onClick={() => handleColumnSelection(item)}>
                                                    ₹{item.amount}/-
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                ))}
                            </div>
                        </div>
                        <Modal show={show} onHide={handleClose} animation={false} size="lg" centered>
                            <Modal.Header >
                                <div className="popup">
                                    <div className="my-4 d-flex">
                                        <div>
                                            <CheckCircleOutlineIcon style={{ fontSize: '8rem' }} className="  tick-icon" />
                                        </div>
                                        <label>
                                            Congratulation <b> {userData.firstName} {userData.lastName} </b> ! You have signed up Successfully Wish You Have A Nice Experience
                                            <div className="popup">
                                                <Button variant="primary" onClick={getLogin}>
                                                    Continue Your Journey
                                                </Button>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </Modal.Header>
                        </Modal>
                        <div className="row">
                            <div className="d-flex justify-content-center mt-2 mb-2">
                                {!isSubscribed ? (
                                    <button type="button" onClick={() => executeRegisterApi()} className="btn btn-theme-light">
                                        {isFirstDataSelected ? "CONTINUE" : "SUBSCRIBE NOW"}
                                    </button>
                                ) : (
                                    <button type="button" className="btn btn-theme-light"
                                        style={{ cursor: 'not-allowed' }}>
                                        {"Already SUBSCRIBED"}
                                    </button>
                                )}
                            </div>
                            <div className="d-flex justify-content-center mt-1 mb-3">
                                <span className="">Membership Note: 18% GST will be added to the fee on checkoutBenefits</span>
                            </div>
                            <div className="col-lg-12 text-center">
                                <div className="sec-heading">
                                    <h2 className="fw-bold">Pricing Question</h2>
                                    <div className="radio-buttons pick-plan">
                                        {
                                            membershipTypes && membershipTypes.map((item: any, idx: any) => (
                                                <label className="mx-2">
                                                    <input type="radio" className="" name="pricingOption" value={item.label}
                                                        checked={priceType === item.label} onChange={handleRadioChange} />
                                                    <span className="ps-1">{item.label}</span>
                                                </label>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {priceType && <FreeQuestions priceType={priceType} />}
            </div>
            <Modal show={showMobileAndEmail} onHide={handleCloseMobileAndEmail} size="lg" centered>
                <Modal.Header >
                    <div className="d-flex align-items-center">
                        <button className="btn" onClick={handleCloseMobileAndEmail}><ArrowBackIcon /></button>
                        <span className="ms-3 fs-5 fw-bold">Update Details</span>
                    </div>
                    <div>
                        <button className="btn" onClick={handleCloseMobileAndEmail}><CloseIcon /></button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mt-3">
                        <Col md={6} className="mb-2">
                            <label className="mb-2">Email</label>
                            <input type="email" className={`input-with-icon form-control p-3 ${emailValidationError ? 'is-invalid' : ''}`}
                                style={{ backgroundImage: 'none' }}
                                value={email}
                                onChange={e => { handleEmailChange(e.target.value) }}
                                placeholder="Email" name="Email" />
                            {emailValidationError && <div className="invalid-feedback">Email required</div>}
                        </Col>
                        <Col md={6} className="mb-2">
                            <label className="mb-2">Mobile</label>
                            <input type="number" className={`input-with-icon form-control p-3 ${mobileValidationError ? 'is-invalid' : ''}`}
                                style={{ backgroundImage: 'none' }}
                                value={mobile}
                                onChange={e => { handleMobileNumberChange(e.target.value) }}
                                placeholder="Mobile" name="Mobile" />
                            {mobileValidationError && <div className="invalid-feedback">Mobile No. required</div>}
                        </Col>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-md full-width btn-theme-light-2 rounded"
                        onClick={handleModalSubmit}>
                        Submit
                    </button>
                </Modal.Footer>
            </Modal>
            <form
                id="sabPaisaForm"
                // action="https://stage-securepay.sabpaisa.in/SabPaisa/sabPaisaInit?v=1"
                action={sabpaisaUrl}
                method="post"
                style={{ display: 'none' }}>
                <input type="text" name="encData" value={encData} />
                <input type="text" name="clientCode" value={clientCode} />
            </form>
        </>
    )
}

export default Pricing;